import './CSVUploader.css';
import React, { useState, useRef, useEffect } from 'react';
import {Vector as VectorSource} from 'ol/source';
import Papa from "papaparse";
import { vectorForCSV as vector } from '../../../../_config/layers';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

function CSVUploader(props) {

  const htmlElement = useRef();

  const feedbackElement = useRef();

  const [data, setData] = useState(undefined);

  const handleChange = (e) => {

    const files = e.target.files;

    if (files) {
      Papa.parse(files[0], {
        complete: function (results) {
          setData(results.data)
        }
      }
      )
    }

  }

  const convertCSVToFeatures = () => {

    try {
      if (!data) { showErrorFeedback(); return false; }

      let latitudeColumn = getLatitudeColumn(data[0]);

      let longitudeColumn = getLongitudeColumn(data[0]);

      if (!latitudeColumn && !longitudeColumn & data.lenght < 2) { showErrorFeedback(); return false; }

      vector.setSource(new VectorSource());

      data.forEach((element, index) => {
        if (index > 0) {

          let feature = generateFeature(data[0], element, longitudeColumn, latitudeColumn);

          vector.getSource().addFeature(feature);
        }
      });

      props.map.getView().fit(vector.getSource().getExtent(), props.map.getSize());

      props.callback();
    } catch (error) {
      console.log(error)
      showErrorFeedback();
    }

  }

  const getLongitudeColumn = (keys) => {

    let i = undefined;

    keys.forEach((element, index) => {
      if (element === "long" || element === "lng" || element === "longitude" || element === "Longitude")
        i = index;
    })

    return i;

  }

  const getLatitudeColumn = (keys) => {
    let i = undefined;

    keys.forEach((element, index) => {
      if (element === "lati" || element === "lat" || element === "latitude" || element === "Latitude")
        i = index;
    })

    return i;
  }

  const generateFeature = (keys, element, longitudeColumn, latitudeColumn) => {
    let obj = mergeKeysAndValues(keys, element);
    return new Feature({
      geometry: generateGeom(element, longitudeColumn, latitudeColumn),
      ...obj
    });
  }

  const generateGeom = (element, longitudeColumn, latitudeColumn) => {
    let long = parseFloat(element[longitudeColumn]);
    let lat = parseFloat(element[latitudeColumn]);
    return new Point([long, lat])
  }

  const mergeKeysAndValues = (keys, values) => {
    return keys.reduce((obj, key, index) => ({ ...obj, [key]: values[index] }), {});

  }

  const handleReset = () => {
    vector.getSource().clear();
  }

  const showErrorFeedback = () => {
    feedbackElement.current.style.visibility = "visible";
  }

  useEffect(convertCSVToFeatures, data)

  useEffect(() => { feedbackElement.current.style.visibility = "hidden"; }, [])

  return (
    <div className="CSVUploader">

      <h6><label htmlFor="csvFormFile" className="form-label">Importar pontos de um arquivo CSV </label></h6>

      <div className="input-group mb-3">
        <input ref={htmlElement} className="form-control" id="csvFormFile"
          type="file"
          accept=".csv,.xlsx,.xls"
          onChange={handleChange}
        />
        <span onClick={handleReset} className="input-group-text CSVUploaderReset">Resetar</span>
      </div>

      <div className="CSVUploaderHint">
        O arquivo .CSV a ser importado precisa ter a sua primeira linha definida apenas com nomes de campos. Dentre estes campos, um precisa se chamar "Latitude" e outro "Longitude". As demais linhas precisam ter dados vinculados aos campos definidos na primeira linha. Dados de latitude e longitude precisam estar em graus decimais e projeção SIRGAS 2000, EPSG 4674. Procure usar ';' como delimitador do arquivo .CSV a ser importado.

        <p><a href="/portal/template.csv" download rel="noreferrer">Acesse o template de arquivo .CSV</a></p>
      </div>

      <div ref={feedbackElement} className="CSVUploaderErrorFeedback">
        Houve uma falha ao tentar importar seu arquivo CSV. Certifique-se de que o mesmo está no formato correto e tente novamente.
      </div>

    </div>
  )

}

export default CSVUploader;