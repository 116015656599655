var elements = ".LayerLink, .HeavyLink,.TermCheckBox,.TermLink,.ModalText, .LayerSwitcherTitle, .GovFooterBar, .LayerTitle, .LayerFolderTitle";
var unity = 0.5;


const resetFont = ()=>{
    let tags = document.querySelectorAll(elements);
    tags.forEach(
        (tag)=>
        {
            let style = window.getComputedStyle(tag, null).getPropertyValue('font-size');
            let currentSize = parseFloat(style);
            tag.style.fontSize = (currentSize - unity*3) + 'px';
        }
    )
}

const incrementFont = ()=>{
    let tags = document.querySelectorAll(elements);
    tags.forEach(
        (tag)=>
        {
            let style = window.getComputedStyle(tag, null).getPropertyValue('font-size');
            let currentSize = parseFloat(style);
            tag.style.fontSize = (currentSize + unity) + 'px';
        }
    )
}

const decrementFont = ()=>{
    let tags = document.querySelectorAll(elements);
    tags.forEach(
        (tag)=>
        {
            let style = window.getComputedStyle(tag, null).getPropertyValue('font-size');
            let currentSize = parseFloat(style);
            tag.style.fontSize = (currentSize - unity) + 'px';
        }
    )
}

export {resetFont, incrementFont, decrementFont};