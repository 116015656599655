import './LocateAddress.css';
import React, { useRef, useState } from 'react';
import { vector } from '../../_config/layers/';
import LocateAddressModal from './component/LocateAddressModal';
import locateaddress from '../../_assets/img/locateaddress_min.png';
import { transformExtent, fromLonLat } from 'ol/proj';
import { Circle as CircleStyle , Fill, Stroke, Style } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

function LocateAddress(props) {

  const restoreElement = useRef();

  const locateAddressInputRef = useRef();

  const [address, setAddress] = useState("")

  const [active, setActive] = useState(false);

  const positionFeature = new Feature();

  const positionFeatureStyle = new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: 'rgb(236, 118, 8)',
      }),
      stroke: new Stroke({
        color: '#000000',
        width: 2,
      }),
    }),
  });

  const handleClick = () => {
   // window.activeFillModal = true;
    setActive(active => !active);
  }

  const pointAddressOnMap = ({ coordinates }) => {

    vector.getSource().clear();
    positionFeature.setGeometry(null);
    coordinates = fromLonLat(coordinates, "EPSG:4326");
    positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
    positionFeature.setStyle(positionFeatureStyle)
    vector.getSource().addFeature(positionFeature)
    
  }

  const zoomAddressOnMap = (bbox) => {

    let extent = transformExtent(bbox, 'EPSG:4326', 'EPSG:4326');
    props.map.getView().fit(extent, props.map.getSize());
    locateAddressInputRef.current.className = 'input-group-text LocateAddressButton';
    setActive(false);

  }

  const locateAddressOnMap = ({ bbox, geometry }) => {

    pointAddressOnMap(geometry)

    zoomAddressOnMap(bbox);

  }

  const handleGeocoding = async () => {
    
    let url = `https://nominatim.openstreetmap.org/search?q=${address}&format=geojson`;
    
    locateAddressInputRef.current.className = 'input-group-text LocateAddressButton is-valid';

    await fetch(url)
      .then((response) => {
        return response.json().then((data) => {
          if (data) {
            if (data && data.features && data.features[0]) {
              locateAddressOnMap(data.features[0])
            } else {
              locateAddressInputRef.current.className = 'input-group-text LocateAddressButton is-invalid';
            }
            return true;
          }
          else {
            locateAddressInputRef.current.className = 'input-group-text LocateAddressButton is-invalid';
            return false;
          }
        }).catch((err) => {
          locateAddressInputRef.current.className = 'input-group-text LocateAddressButton is-invalid';
          console.log(err);
        })
      }).catch((err) => {
        locateAddressInputRef.current.className = 'input-group-text LocateAddressButton is-invalid';
        console.log(err);
      });
  }

  return (
    <div id="LocateAddress" className="LocateAddress">
      <button className='HorizontalButton'
        type='button'
        alt='Localizar endereço'
        title='Localizar endereço'
        onClick={handleClick}
        ref={restoreElement}
      >
        <i className="material-icons">signpost</i> {/* Ícone para localizar endereço */}
      </button>
      <LocateAddressModal locateAddressInputRef={locateAddressInputRef} address={address} setAddress={setAddress} handleGeocoding={handleGeocoding} active={active} callback={setActive}></LocateAddressModal>
    </div>
  )

}

export default LocateAddress;