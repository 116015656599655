import React, { useEffect, useState } from 'react';
import './FontSizeToolbar.css';
import { resetFont, incrementFont, decrementFont } from '../../../../utils/FontHandler';

const FontSizeToolbar = (props) => {

  const [fontSize, setFontSize] = useState(0);



  return (
    <div className="FontSizeToolbar">
      <div className="FontSizeToolbarAcessibility">
        <span>Acessibilidade</span>
        <button disabled={fontSize == 3 ? false : true} onClick={() => {setFontSize(0); resetFont();}}>A</button>
        <button disabled={fontSize == 0 ? true : false} onClick={() => {setFontSize(fontSize - 1);decrementFont();}}>A-</button>
        <button disabled={fontSize == 3 ? true : false} onClick={() => {setFontSize(fontSize + 1);incrementFont();}}>A+</button>
      </div>
    </div>
  );
}


export default FontSizeToolbar;
