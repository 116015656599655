import React from 'react';
import LinkToolbar from './components/LinkToolbar/LinkToolbar';
import FontSizeToolbar from './components/FontSizeToolbar/FontSizeToolbar';
import './GovHeaderBar.css';

const GovHeaderBar = (props) => {



  return (
    <div className="GovHeaderBar">
      <LinkToolbar></LinkToolbar>
      <FontSizeToolbar></FontSizeToolbar>
    </div>
  );
}


export default GovHeaderBar;
