import React, { useState, useEffect } from 'react';
import { ServerTypeHelper } from '../../../../_config/helpers';
import scopedFolder from '../../../../_assets/img/scopedFolder.png';
import './LayerScope.css';


const LayerScope = (props) => {

  const [layers, setLayers] = useState([...props.map.getLayers().getArray()]);

  const [layerSearchTerms, setLayerSearchTerms] = useState([]);

  const [LayerScope, setLayerScope] = useState([]);

  const activeFolderContentVisibility = (content) => {

    content.style.visibility = 'visible';

    content.style.display = 'inherit';

    content.style.opacity = '1';

  }

  const handleLayerSearchTermsChange = () => {

    try {
      layerSearchTerms.forEach(
        (layerId) => {
          let e = (document.querySelector(`#layer${layerId}`));
          let label = (document.querySelector(`#layerTitle${layerId}`));
          label.style.color = `green`;
          let layerFolder = e.parentNode.parentNode.parentNode.parentNode.parentNode;
          let layerFolderImage = (document.querySelector(`#${layerFolder.id}image`));
          layerFolderImage.style.color = '#86c777';
          let content = e.parentNode.parentNode.parentNode.parentNode;
          let key = 0;

          activeFolderContentVisibility(content);

          while (layerFolder.parentNode.parentNode.className === `LayerFolder` && key < 5) {
            content = layerFolder.parentNode;
            layerFolder = layerFolder.parentNode.parentNode;
            layerFolderImage = (document.querySelector(`#${layerFolder.id}image`));
            layerFolderImage.style.color = '#86c777'; 
            activeFolderContentVisibility(content);
            key++;
          }

        }

      )
    } catch (e) { console.log(e) }
  }

  const handleLayerTargetChange = (e) => {

    const layerTargets = [];

    layers.forEach((layer, key) => { if (layer.get(`title`) === e.target.value) layerTargets.push(key) });

    setLayerSearchTerms(layerTargets);

    return true;

  }

  const buildLayerTarget = (layer, id) => {

    return (<option key={id} className={'LayerTarget'} value={layer.get(`title`)}></option>);

  }

  const buildLayerScope = () => {

    return setLayerScope(layers.map((layer, key) => layer.get(`serverType`) === ServerTypeHelper.GEOSERVER && buildLayerTarget(layer, key)));

  }

  useEffect(buildLayerScope, [layers]);

  useEffect(handleLayerSearchTermsChange, [layerSearchTerms]);

  return (
    <div id={"LayerScope"} className={"LayerScope"} >

      <div className={"LayerTargerFilterSet"}>
        <div className="input-group input-group-sm mb-3">
          <span className="input-group-text" id="basic-addon1">🔎</span>
          <input onChange={handleLayerTargetChange} placeholder={"Digite o nome da camada que você procura..."} className={"form-control LayerTargerFilter"} list={"LayerTargets"}></input>
        </div>

        <datalist id={`LayerTargets`} className={"LayerTargets"}>
          {LayerScope}
        </datalist>

      </div>


    </div>
  );
}

export default LayerScope;
