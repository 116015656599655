import React, { useState, useEffect } from 'react';
import './HintModal.css';
import HeavyLink from '../../../LayerSwitcher/components/HeavyLink'
import modal_logo from '../../../../_assets/img/modal_logo.png'
import modal_logo_min from '../../../../_assets/img/modal_logo_min.png'
import { Modal } from 'react-bootstrap';


const HintModal = ({ active, callback }) => {

  const clientWidth = document.documentElement.clientWidth;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (active)
      handleShow();
  }, [active])

  useEffect(() => {
    callback(show)
  }, [show])

  return (

    <Modal className="HintModal" backdrop="static" keyboard={false} size={`xl`} show={show} onHide={handleClose} data-keyboard="false" data-backdrop="static">

      <img className="img-thumbnail" alt="Logo" title="Logo" src={clientWidth > 350 ? modal_logo : modal_logo_min} ></img>


      <Modal.Body >

        <p>A plataforma <span>PEDEA</span> é um ambiente virtual para visualizar os dados espaciais organizados pelos órgãos ambientais e de fiscalização do Estado do Ceará</p>

        <p>A <span>PEDEA</span> é compostas por três módulos:</p>

        <p><span>1. Base de dados:</span> um módulo que guarda e protege todos os dados da plataforma;</p>
        
        <p><span>2. Servidor geográfico:</span> o programa que processa e trata os dados presentes na base em mapas digitais e os mantém disponíveis na internet;</p>
        
        <p><span>3. Portal:</span> é a própria aplicação, acessível no endereço (https://pedea.sema.ce.gov/portal) onde é possível visualizar e interagir com todos os mapas disponíveis no servidor geográfico e em outros servidores disponíveis na internet.`;</p>

        <p><span>Precisa de ajuda para interagir com a plataforma?</span></p>

        <div className="row">
          <div className="col-6">
            <HeavyLink btClass='btn btn-outline-dark' content='Consultar manual do usuário' href='/portal/manual.pdf' />
          </div>
          <div className="col-6">
            <HeavyLink btClass='btn btn-outline-dark' content='Vídeo tutorial da plataforma' href='https://youtu.be/TP_4v8EfJZ4' />
          </div>
          
        </div>

        <div className="row">
          <div className="col-6">
            <HeavyLink btClass='btn btn-outline-dark' content='Consultar site do Atlas' href='https://www.sema.ce.gov.br/89965-2/planejamento-costeiro-e-marinho-do-ceara/atlas-digital-costeiro-e-marinho-do-ceara/' />
          </div>
          <div className="col-6">
            <HeavyLink btClass='btn btn-outline-dark' content='Consultar site do Programa CCMA' href='https://www.sema.ce.gov.br/89965-2/' />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <HeavyLink btClass='btn btn-outline-dark' content='Consultar informações do ZEEC' href='https://www.sema.ce.gov.br/gerenciamento-costeiro/zoneamento-ecologico-economico-da-zona-costeira-zeec/documentos-previos-para-consulta-publica-do-zeec/' />
          </div>
          <div className="col-6">
            <HeavyLink btClass='btn btn-outline-dark' content='Consultar painel do CEUC' href='https://app.powerbi.com/view?r=eyJrIjoiZDY0MGQyZDEtYjI4NS00MTE3LThkOTgtNGVjMTA4ZTZjNTExIiwidCI6ImY2MjAxZmQxLWFlMzctNGZlMS1hZDJjLTUwNTc3ZTJmYTEzNiJ9' />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <HeavyLink btClass='btn btn-outline-dark' content='Consultar perguntas frequentes' href='/portal/faq.html' />
          </div>
          <div className="col-6">
            <HeavyLink btClass='btn btn-outline-dark' content='Consultar histórico evolutivo' href='/portal/versioning.html' />
          </div>
        </div>

        <br/>

        <p>
          Leia nossos <a className="TermLink" href={'/portal/termos_e_condicoes_de_uso_pedea.pdf'} target='_blank' rel="noreferrer">Termos e Condiçẽs de Uso</a> e  <a className="TermLink" href={'/portal/aviso_privacidade_pedea.pdf'} target='_blank' rel="noreferrer">Aviso de Privacidade</a>.
        </p>

        <div className="row g-3">
          <div className="col-8">
          </div>
          <div className="d-grid col-3">
            <button onClick={handleClose} className="btn btn-secondary btn-lg">Fechar</button>
          </div>
        </div>

      </Modal.Body>

    </Modal>
  );

}


export default HintModal;