import './PngExporter.css';
import React, { useRef } from 'react';
import { ServerTypeHelper } from '../../_config/helpers';
import ScaleLine from 'ol/control/ScaleLine';
import pngExporter from '../../_assets/img/pngExporter.png';
import pngExporter_min from '../../_assets/img/pngExporter_min.png';

function PngExporter({ map }) {

  const htmlElement = useRef();

  const clientWidth = document.documentElement.clientWidth;

  function drawScale(ctx, map) {
    const scaleLine = new ScaleLine();
    scaleLine.setMap(map);
    const scaleElement = scaleLine.element;
    scaleElement.style.position = 'absolute';
    scaleElement.style.top = '-1000px';
    document.body.appendChild(scaleElement);

    map.renderSync();
    const scaleText = scaleElement.innerText;
    document.body.removeChild(scaleElement);

    ctx.font = 'bold 16px Arial';
    ctx.fillStyle = 'black';
    ctx.fillText(scaleText, 10, map.getSize()[1] - 10);
  }

  // Função para desenhar a seta do norte no canvas
  function drawNorthArrow(ctx, map) {
    ctx.beginPath();
    ctx.moveTo(map.getSize()[0] - 30, 30);
    ctx.lineTo(map.getSize()[0] - 20, 50);
    ctx.lineTo(map.getSize()[0] - 40, 50);
    ctx.closePath();
    ctx.fillStyle = 'black';
    ctx.fill();
    ctx.stroke();
    ctx.font = '12px Arial';
    ctx.fillText('N', map.getSize()[0] - 25, 25);
  }

  // Função para desenhar a marca d'água no canvas
  function drawWatermark(ctx, callback) {
    const img = new Image();
    img.src = '/portal/logo-pedea-wm.png';
    img.onload = function () {
      ctx.drawImage(img, 1, 5, 464, 50);
      callback();
    }
  }

  function getActiveLayers() {
    const layers = map.getLayers().getArray();
    const activeLayers = layers.filter(layer => layer.getVisible() && layer.get(`serverType`) === ServerTypeHelper.GEOSERVER).map(layer => layer.get('title'));
    return activeLayers.join(', ');
  }

  function wrapText(ctx, text, x, y, maxWidth, lineHeight) {
    const words = text.split(' ');
    let line = '';
    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        ctx.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
      } else {
        line = testLine;
      }
    }
    ctx.fillText(line, x, y);
  }

  function drawFooter(ctx, map, footerHeight) {
    const activeLayers = getActiveLayers();
    const footerText = `Fonte: PEDEA CE/SEMA | ${activeLayers}`;
    const lineHeight = 20;
    ctx.font = 'bold 12px Arial';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, map.getSize()[1], map.getSize()[0], footerHeight);
    ctx.fillStyle = 'black';
    wrapText(ctx, footerText, 10, map.getSize()[1] + 20, map.getSize()[0] - 20, lineHeight);
  }


  // Função para exportar o mapa em formato PNG
  function handleClick() {
    map.renderSync();

    const mapCanvas = document.createElement('canvas');
    const size = map.getSize();
    const footerHeight = 50; // Altura adicional para o rodapé
    mapCanvas.width = size[0];
    mapCanvas.height = size[1] + footerHeight;
    const mapContext = mapCanvas.getContext('2d');

    Array.prototype.forEach.call(document.querySelectorAll('.ol-layer canvas'), function (canvas) {
      if (canvas.width > 0) {
        const opacity = canvas.parentNode.style.opacity;
        mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);

        const transform = canvas.style.transform;
        const matrix = transform.match(/^matrix\(([^\(]*)\)$/)[1].split(',').map(Number);
        CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);

        mapContext.drawImage(canvas, 0, 0);
      }
    });

    // Desenhar a marca d'água no canvas e, depois, os outros elementos
    drawWatermark(mapContext, function () {
      // drawLegend(mapContext, map);
      drawNorthArrow(mapContext, map);
      drawScale(mapContext, map);
      drawFooter(mapContext, map, size[1] + footerHeight);


      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(mapCanvas.msToBlob(), 'map.png');
      } else {
        const link = document.createElement('a');
        link.href = mapCanvas.toDataURL();
        link.download = 'map.png';
        link.click();
      }
    });
  }

  return (
    <>
      <button
        type='button'
        alt='Captura de tela: salve a tela que você está visualizando em formato de imagem.'
        title='Captura de tela: salve a tela que você está visualizando em formato de imagem.'
        onClick={handleClick}
        ref={htmlElement}
        className="navbar-toggler PngExporter"
      >
        <i className="material-icons">camera_alt</i>
      </button>
      <a crossOrigin="anonymous" id='image-download' download="map.png" href='#'> </a>
    </>
  )

}

export default PngExporter;