import React from 'react';
import './MetaLink.css';
import icon_txt from '../../../../_assets/img/icon_txt.png';
import icon_txt_min from '../../../../_assets/img/icon_txt_min.png';



const MetaLink = (props) => {

  const clientWidth = document.documentElement.clientWidth;

  return (
      <a className={"MetaLink"} href={`/portal/metadata/${props.wmsName.split(':')[1]}_metadados.txt`} download rel="noreferrer">
        <button className='HorizontalButton'
        type="button"
        title={`Acessar metadados do mapa.`}
        alt={`Meta`}
      >
        <i className="material-icons">description</i>
      </button>
      </a>
    );
}


export default MetaLink;
