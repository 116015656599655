import './GridLayer.css';
import React, { useRef, useState } from 'react';
import graticule_min from '../../_assets/img/graticule_min.png';
import { vector } from '../../_config/layers/';

function GridLayer(props) {

  const restoreElement = useRef();

  const [active, setActive] = useState(false);

  const [gridLayer, setGridLayer] = useState(props.map.getLayers().getArray()[props.map.getLayers().getArray().length-1]);

  const handleClick = () => {
    
    gridLayer.setVisible(!gridLayer.getVisible());
    setActive(!active);

  }


  return (
    <div id="GridLayer" className="GridLayer">
      <button className='HorizontalButton'
        type='button'
        alt='Mostrar grade de coordenadas'
        title='Mostrar grade de coordenadas'
        onClick={handleClick}
        ref={restoreElement}
      >
        <i className="material-icons">{active ? "grid_off" : "grid_on"}</i> {/* Ícone para mostrar grade de coordenadas */}
      </button>
    </div>
  )

}

export default GridLayer;