import './Welcome.css';
import React, { useRef, useState, createContext } from 'react';
import MapWrapper from '../MapWrapper/';
import ScaleBar from '../ScaleBar/';
import MouseWatcher from '../MouseWatcher';
import Header from '../../containers/Header';
import Navbar from '../Navbar/';
import LayerSwitcher from '../LayerSwitcher/';
import ResetZoom from '../ResetZoom';
import LocateMe from '../LocateMe';
import LegendPopup from '../LegendPopup';
import LocateAddress from '../LocateAddress';
import HintWindow from '../HintWindow/';
import FileUploader from '../FileUploader/';
import BaseLayer from '../BaseLayer/';
import GridLayer from '../GridLayer/';
import Toolbar from '../Toolbar/';
import GovFooterBar from '../GovFooterBar/';
import GovHeaderBar from '../GovHeaderBar/';
import HorizontalToolbarToggler from '../HorizontalToolbarToggler/';
import { map, explorer } from '../../_config/map';
import StatisticGraphics from '../StatisticGraphics';
import StoryTelling from '../StoryTelling/StoryTelling';


const LayerVisibleCountContext = createContext(1);


const Welcome = () => {

  const collapseSwitcher = true;

  const switcherRef = useRef();

  const [visibleLayer, setVisibleLayer] = useState(0)

  const clientWidth = document.documentElement.clientWidth;

  const clientHeight = document.documentElement.clientHeight;

  const hasNewVisibleLayer = (index) => {
    setVisibleLayer(index);
  }

  return (
    
      <div className="Welcome">
        <GovHeaderBar />
        <Header>
          <Navbar map={map} switcherRef={switcherRef} >
            {clientWidth > 900 ? (<Toolbar map={map} />) : null}
          </Navbar>
        </Header>
        <MapWrapper map={map}>
          <ScaleBar map={map} />
          {clientWidth > 900 || clientHeight > 750? (<LegendPopup  map={map} LayerVisibleCountContext={LayerVisibleCountContext} visibleLayer={visibleLayer} />) : null}
          <MouseWatcher map={map} />
          <ResetZoom map={map} />
          <BaseLayer map={map} />
          <GridLayer map={map} />
          <LocateMe map={map} />
          <LocateAddress map={map} />
          <FileUploader map={map} />
          <StatisticGraphics map={map}/>
          <StoryTelling map={map}/>
          <HintWindow map={map} />
          <HorizontalToolbarToggler map={map} />
          <LayerSwitcher map={map} hasNewVisibleLayer={hasNewVisibleLayer} switcherRef={switcherRef} explorer={explorer} collapsed={collapseSwitcher} />
          {clientWidth <= 900 ? (<Toolbar map={map} />) : null}
        </MapWrapper>
        <GovFooterBar />
      </div>
    
  )
}

export default Welcome;