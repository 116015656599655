import React, { useState } from 'react';
import './Logo.css';
import header from '../../../../_assets/img/header.png'
import header_min from '../../../../_assets/img/header_min.png'
import WelcomeModal from '../WelcomeModal';


const Logo = () => {

  const clientWidth = document.documentElement.clientWidth;

  const [active, setActive] = useState(false);

  const handleClick = () => {
    //window.activeFillModal = true;
    setActive(active => !active);
  }

  return (
    <>
      <img onClick={handleClick} className='LogoImage img-fluid' src={clientWidth > 600 ? header : header_min} alt={`Logo`}></img>
      <a onClick={handleClick} href='#' className='navbar-brand Logo'></a>
      <WelcomeModal callback={setActive} active={active}></WelcomeModal>

    </>
  );

}


export default Logo;
