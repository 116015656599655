import './BaseLayer.css';
import React, { useRef, useState, useEffect } from 'react';
import TemplateModal from '../../components/TemplateModal/TemplateModal';
import base_layer_min from '../../_assets/img/base_layer_min.png';
import { ServerTypeHelper } from '../../_config/helpers'
import { vector } from '../../_config/layers/';


let baseLayerSwitcher = [];

function BaseLayer(props) {

  const restoreElement = useRef();

  const [active, setActive] = useState(0);

  const [baseLayersJSX, setBaseLayersJSX] = useState([]);

  const handleClick = () => {
    //window.activeFillModal = true;
    vector.getSource().clear();
    setActive(active => active + 1);
  }

  const handleBaseLayerClick = (event) => {

    const key = event.target.value;

    baseLayerSwitcher.forEach(
      (layer) => {
        if (layer.get('title') === key)
          layer.setVisible(true)
        else {
          layer.setVisible(false)
          //console.log(layer)
        }
      }
    );

  }

  const buildBaseLayerTree = () => {

    const currentLayersJSX = [];

    const layersAux = [];

    let provider = '';

    props.map.getLayers().getArray().forEach(
      (layer, index) => {

        let isBaseLayer = layer.get('serverType') !== ServerTypeHelper.GEOSERVER && layer.get('title') && layer.get('title') !== 'Grade';

        if (isBaseLayer) {

          let currentProvider = layer.get('folder');

          if (isBaseLayer && currentProvider !== provider) {
            provider = currentProvider;
            currentLayersJSX.push(<br key={'brBase' + index} />);
            currentLayersJSX.push(<h5 key={'hBase' + index}>{provider}</h5>);
            currentLayersJSX.push(<hr key={'hrBase' + index} />);
          }

          isBaseLayer &&
            currentLayersJSX.push(

              <div className='LayerFolderItem' key={index}>
                <div className="LayerInputContainer">
                  <div className='LayerRadioContainer'>
                    <input className='LayerRadio form-radio-input' type='radio' name='baseLayer' defaultChecked={layer.getVisible()} id={'layer' + index} value={layer.get('title')} onClick={handleBaseLayerClick} />
                  </div>
                  <div className='LayerTitleContainer'>
                    <span className='LayerTitle' id={'layerTitle' + index}>🌎 {layer.get('title')}</span>
                  </div>
                </div>
              </div>

            )

          isBaseLayer && layersAux.push(layer)

        }
      }
    )

    baseLayerSwitcher = (layersAux);

    setBaseLayersJSX(currentLayersJSX);

  }

  useEffect(buildBaseLayerTree, [active]);

  return (
    <div id="BaseLayer" className="BaseLayer">
      
      <button className='HorizontalButton'
        type='button'
        alt='Trocar mapa base'
        title='Trocar mapa base'
        onClick={handleClick}
        ref={restoreElement}
      >
        <i className="material-icons">language</i>
      </button>


      <TemplateModal title={`Trocar mapa base`} active={active}>

        {baseLayersJSX}

      </TemplateModal>
    </div>
  )

}

export default BaseLayer;