import './LegendPopup.css';
import React, { useEffect, useRef } from 'react';
import { ServerTypeHelper } from '../../_config/helpers';
import ImageWMS from 'ol/source/ImageWMS';
import close from '../../_assets/img/close_min.png';
import legend_button_min from '../../_assets/img/legend_button_min.png';

function LegendPopup(props) {

  const LegendPopupElement = useRef();

  const LegendButtonElement = useRef();

  const ID_TYPE = "LayerOpacitySlider";

  const toggleLegend = () => {

    toggleLegendPopup();

    toggleLegendButton();

  }

  const toggleLegendButton = () => {

    const element = LegendButtonElement.current;

    if (element.style.visibility === 'visible') {

      element.style.visibility = 'hidden';

      element.style.opacity = '0';

      element.style.zIndex = '0';

    } else {

      element.style.visibility = 'visible';

      element.style.opacity = '1';

      element.style.zIndex = '1';

    }

  }

  const toggleLegendPopup = () => {

    const element = LegendPopupElement.current;

    if (element.style.visibility === 'visible' || !(element.style.visibility)) {

      element.style.visibility = 'hidden';

      element.style.opacity = '0';

      element.style.zIndex = '0';

    } else {

      element.style.visibility = 'visible';

      element.style.opacity = '1';

      element.style.zIndex = '1';

    }

  }

  const checkVisibleLayers = () => {

    let queryableLayers = [];

    for (let i = 0; i < props.map.getLayers().getArray().length; i++) {
      if (props.map.getLayers().getArray()[i].getVisible() && props.map.getLayers().getArray()[i].get('queryable')) {
        handleGetLegendGraphic(props.map.getLayers().getArray()[i], i);
      }
    }

    return queryableLayers;

  }


  const handleGetLegendGraphic = (layer, index) => {

    if (layer.getVisible() && (layer.get('serverType') === ServerTypeHelper.GEOSERVER || layer.get('serverType') === ServerTypeHelper.MAPSERVER)) {

      const wmsSource = new ImageWMS({
        url: layer.get('url'),
        params: { 'LAYERS': layer.get('wmsName'), TRANSPARENT: true },
        ratio: 1,
        transparent: 'true',
        serverType: layer.get('serverType'),
      });

      const graphicUrl =
        wmsSource.getLegendUrl(props.map.getView().getResolution(),
          { "legend_options": "fontColor:#FFFFFF;fontSize:10", "transparent": "true" },
        );
      const img = document.getElementById('popUpLayerLegend' + index);
      const legendTitle = document.getElementById('popUpLayerLegendTitle' + index);
      img.src = graphicUrl;
      img.onclick = () => window.open(graphicUrl, '_blank').focus();
      img.style.visibility = 'visible';
      img.style.display = 'inherit';
      img.style.cursor = 'pointer';
      legendTitle.style.visibility = 'visible';
      legendTitle.style.display = 'inherit';
    } else if (!layer.getVisible()) {
      const legendTitle = document.getElementById('popUpLayerLegendTitle' + index);
      const img = document.getElementById('popUpLayerLegend' + index);
      img.style.visibility = 'hidden';
      img.style.display = 'none';
      legendTitle.style.visibility = 'hidden';
      legendTitle.style.display = 'none';
    }

  }

  const handleOpacity = (event) => {

    let value = parseFloat(event.target.value);

    let index = parseInt(event.target.id.replace(ID_TYPE, ""));

    props.map.getLayers().getArray()[index].setOpacity(value);

  }

  useEffect(() => {

    checkVisibleLayers();

  }, [props])

  return (
    <>
      <div id="LegendButton" className="LegendButton" ref={LegendButtonElement}>
      <button className='HorizontalButton'
          type='button'
          alt='Abrir popup de legenda'
          title='Abrir popup de legenda'
          onClick={toggleLegend}
        >
          <i className="material-icons">legend_toggle</i> {/* Ícone para abrir popup de legenda */}
        </button>
      </div>
      <div className="LegendPopup" ref={LegendPopupElement}>

        <div className='PopUpLayerLegendHeader'>
          <span className='PopUpLayerLegendHeaderTitle'>
            Legendas
          </span>
          <span className='PopUpLayerLegendHeaderToggler'>
          <button className='HorizontalButton'
              type='button'
              title='Fechar explorador de camadas.'
              onClick={toggleLegend}
              alt='Fechar!'
            >
              <i className="material-icons">close</i> {/* Ícone para fechar */}
            </button>
          </span>
        </div>

        <div className="LegendPopupContent">
          {props.map.getLayers().getArray().map((layer, index) =>

            layer.getVisible() && (layer.get('serverType') === ServerTypeHelper.GEOSERVER) ?

              <div key={'popUpLayerItem' + index} className="PopUpLayerItem">
                <span id={'popUpLayerLegendTitle' + index} className='PopUpLayerLegendTitle'> {layer.get('title')} </span>
                <img alt="N/A" id={'popUpLayerLegend' + index} className='PopUpLayerLegend' />
                <input id={ID_TYPE + index} onInput={handleOpacity} className="LayerOpacity" type="range" defaultValue="1" min="0" max="1" step="0.01" />
              </div>

              :

              null

          )}
        </div>
      </div>
    </>
  )

}

export default LegendPopup;