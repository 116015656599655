import './HorizontalToolbarToggler.css';
import React, { useEffect, useRef, useState } from 'react';
import toolbox_on_min from '../../_assets/img/toolbox_on_min.png';
import toolbox_off_min from '../../_assets/img/toolbox_off_min.png';

function HorizontalToolbarToggler(props) {

  const element = useRef();

  const [active, setActive] = useState(false);

  const handleClick = () => {

    setActive(!active);

  }

  const handeToolsVisibility = () => {

    element.current.src = active ? toolbox_on_min : toolbox_off_min;

    setToolVisibility("BaseLayer");
    setToolVisibility("FileUploader");
    setToolVisibility("StatisticGraphics");
    setToolVisibility("StoryTelling");
    setToolVisibility("GridLayer");
    setToolVisibility("HintWindow");
    setToolVisibility("LocateAddress");
    setToolVisibility("LocateMe");


  }

  const setToolVisibility = (tool) => {

    if (active) {
      document.getElementById(tool).style.zIndex = '2';
      document.getElementById(tool).style.opacity = '1';
      document.getElementById(tool).style.visibility = 'visible';
    }
    else {
      document.getElementById(tool).style.opacity = '0';
      document.getElementById(tool).style.zIndex = '0';
      document.getElementById(tool).style.visibility = 'hidden';
    }

  }

  useEffect(() => handeToolsVisibility(), [active])


  return (
    <div className="HorizontalToolbarToggler">
      <button className='HorizontalButton'
        type='button'
        alt='Abrir menu de ferramentas'
        title='Abrir menu de ferramentas'
        ref={element}
        onClick={handleClick} // Adiciona o evento para trocar o ícone
      >
        <i className="material-icons">
          {active ? "hardware" : "construction"} {/* Ícone para abrir ou fechar menu de ferramentas */}
        </i>
      </button>
    </div>
  )

}

export default HorizontalToolbarToggler;