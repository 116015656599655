const zcmData = 
`1;Biodiversidade;Áreas protegidas;;;0604_CE_poligonal_uc_estaduais_2023_pol;UC Estaduais (CEUC/SEMA_2023);CEUC/SEMA;tipounidad;Sim
2;Biodiversidade;Áreas protegidas;;;0604_CE_poligonal_uc_federais_2023_pol;UC Federais (CNUC/ICMBio_2023);CNUC/ICMBio;tipounidad;Sim
3;Biodiversidade;Áreas protegidas;;;0604_CE_poligonal_uc_municipais_2023_pol;UC Municipais (CEUC/SEMA_2023);CEUC/SEMA;tipounidad;Sim
4;Biodiversidade;Áreas protegidas;;;0604_rppn_2023_pol;RPPN (CEUC/SEMA_2023);CEUC/SEMA;grupo;Sim
5;Biodiversidade;Áreas protegidas;;;0604_CE_cor_ecologico_pacoti_2000_pol;Corredor Ecológico do Rio Pacoti (SEMA_2000);SEMA;faixa;
6;Biodiversidade;Áreas prioritárias;;;0605_CE_area_prio_caatinga_2005_pol;Áreas Prioritária da Caatinga (MMA_2005);MMA;import_bio;
7;Biodiversidade;Áreas prioritárias;;;0605_CE_area_hibrida_caatinga_2005_pol;Áreas Híbrida da Caatinga (MMA_2005);MMA;impor_bio;
8;Biodiversidade;Infraestrutura e serviços de suporte a UC;;;0601_CE_pontos_turisticos_visitacao_uc_2022_pto;Pontos de Visitação (SEMA_2022);SEMA;nome_uc;
9;Biodiversidade;Infraestrutura e serviços de suporte a UC;;;0601_CE_sede_uc_2022_pto;Sede das UC (SEMA_2022);SEMA;nome_uc;
10;Biodiversidade;Infraestrutura e serviços de suporte a UC;;;0601_CE_lei_mata_atlantica_2006_pol;Lei da Mata Atlântica (MMA_2006);MMA;cobe_veget;
11;Biodiversidade;Reserva da Biosfera;;;0602_CE_postos_biosfera_matlantica_2021_pto;Postos RBMA (SEMA_2021);SEMA;posto;
12;Biodiversidade;Serviços Ambientais;;;0603_CE_coletas_sementes_nativas_2020_pto;Coleta de sementes florestais nativas (SEMA_2020);SEMA;especie;
13;Educação Ambiental;Capacitações;;;0300_CE_formacao_educ_amb_2015_2022_pol;Formação Educação Ambiental (SEMA_2022);SEMA;Simbologia não categorizada por atributo;
14;Educação Ambiental;Capacitações;;;0300_CE_multiplicadores_educ_amb_2015_2022_pol;Multiplicadores Educação Ambiental (SEMA_2022);SEMA;Simbologia não categorizada por atributo;
15;Educação Ambiental;Plano de Ação Comunitária;;;0302_CE_pac_2021_pto;Plano de Ação Comunitária (SEMA_2021);SEMA;eixo_temat;
16;Educação Ambiental;Plano de Ação Comunitária;;;0302_CE_pac_2022_pto;Plano de Ação Comunitária (SEMA_2022);SEMA;eixo_temat;
17;Educação Ambiental;Plano de Ação Comunitária;;;0302_CE_pac_2023_pto;Plano de Ação Comunitária (SEMA_2023);SEMA;eixo_temat;
18;Educação Ambiental;Municípios Promotores Cultura Oceânica;;;0301_CE_munic_cultura_oceanica_2023_pol;Municipios com Cultura Oceânica (CBMCE_2023);CBMCE;Simbologia não categorizada por atributo;
19;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_oleodutos_2022_lin;Oleodutos (EPE_2022);EPE;duto;Sim
20;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_base_combustiveis_2020_pto;Bases combustíveis (EPE_2020);EPE;Simbologia não categorizada por atributo;Sim
21;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_bases_glp_2020_pto;Bases GLP (EPE_2020);EPE;Simbologia não categorizada por atributo;Sim
22;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_gasoduto_distribuicao_2020_lin;Gasodutos de distribuição RMF (EPE_2020);EPE;Simbologia não categorizada por atributo;
23;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_gasodutos_transporte_2020_lin;Gasodutos de transporte (EPE_2020);EPE;Simbologia não categorizada por atributo;Sim
24;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_plantas_biodisel_2020_pto;Usinas biodisel (EPE_2020);EPE;Simbologia não categorizada por atributo;
25;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_plantas_biogas_2020_pto;Usinas biogás (EPE_2020);EPE;Simbologia não categorizada por atributo;
26;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_plantas_etanol_2020_pto;Usinas etanol (EPE_2020);EPE;Simbologia não categorizada por atributo;
27;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_subestacao_existente_2020_pto;Substação elétrica (EPE_2020);EPE;Simbologia não categorizada por atributo;
28;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_subestacao_planejada_2020_pto;Substação elétrica planejada (EPE_2020);EPE;Simbologia não categorizada por atributo;
29;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_termeletrica_fossil_existente_2020_pto;Usinas termelétricas (EPE_2020);EPE;Simbologia não categorizada por atributo;Sim
30;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_terminais_gnl_2020_pto;Usinas Gás Natural (EPE_2020);EPE;Simbologia não categorizada por atributo;
31;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_usinas_solar_2020_pto;Usinas solares (EPE_2020);EPE;Simbologia não categorizada por atributo;Sim
32;Energia e Telecomunicações;Matriz Energética e Elétrica;;;1400_CE_usinas_solar_planejada_2020_pto;Usinas solar planejadas (EPE_2020);EPE;Simbologia não categorizada por atributo;
33;Energia e Telecomunicações;Sistema de transmissão elétrica;;;1401_CE_linhas_transmissao_existente_2020_lin;Linhas de transmissão de energia (EPE_2021);EPE;Simbologia não categorizada por atributo;Sim
34;Energia e Telecomunicações;Sistema de transmissão elétrica;;;1401_CE_linhas_transmissao_planejada_2020_lin;Linhas de transmissão de energia planejadas (EPE_2020);EPE;Simbologia não categorizada por atributo;Sim
35;Fiscalização Ambiental;Fiscalização;;;0200_CE_auto_infracao_2012_2018_pto;Auto de infração (SEMACE_2012-2018);SEMACE;Simbologia não categorizada por atributo;
36;Fiscalização Ambiental;Fiscalização;;;0200_CE_auto_infracao_2019_2023_pto;Auto de infração (SEMACE_2019-2023);SEMACE;Simbologia não categorizada por atributo;
37;Fiscalização Ambiental;Fiscalização;;;0200_CE_termo_embargo_2012_2023_pto;Termo de Embargo (SEMACE_2012-2023);SEMACE;Simbologia não categorizada por atributo;
38;Fiscalização Ambiental;Fiscalização;;;0200_CE_termo_embargo_2012_2023_pol;Termo de Embargo (SEMACE_2012-2023);SEMACE;Simbologia não categorizada por atributo;
39;Hidrografia;Açudes;;;0803_CE_acudes_monitorados_2018_pol;Açudes Monitorados (COGERH_2018);COGERH;Simbologia não categorizada por atributo;
40;Hidrografia;Bacias Hidrograficas;;;0800_CE_bacias_hidrograficas_2000_pol;Bacias Hidrográficas (COGERH_2000);COGERH;bacias;Sim
41;Hidrografia;Espelhos D'água;;;0802_CE_espelhos_dagua_2021_pol;Espelhos D'água (FUNCEME_2021);FUNCEME;Simbologia não categorizada por atributo;Sim
42;Hidrografia;Infraestrutura Hidrica;;;0803_CE_reservatorios_monitorados_2022_pol;Reservatorios Monitorados (COGERH_2022);COGERH;proprietar;Sim
43;Hidrografia;Rede de Drenagem;;;0805_CE_drenagem_rios_principais_2022_lin;Rios Principais (ANA);ANA;nome_compl;Sim
44;Hidrografia;Rede de Drenagem;;;0805_ce_rede_drenagem_2022_lin;Rede de Drenagem (ANA);ANA;Simbologia não categorizada por atributo;Sim
45;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Aspectos Socieconômicos Costeiro e Marinho;Cartografia Social do Mar;1200_CE_areas_pesca_artesanal_2022_pol;Áreas de pesca artesanal (CCMA-PCM_2022);CCMA-PCM;municipio;Sim
46;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Aspectos Socieconômicos Costeiro e Marinho;Cartografia Social do Mar;1200_CE_biodiversidade_costeira_marinha_2022_pto;Biodiversidade costeira e marinha (CCMA-PCM_2022);CCMA-PCM;nome;
47;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Aspectos Socieconômicos Costeiro e Marinho;Cartografia Social do Mar;1200_CE_direcoes_embarcacoes_pesqueiras_2022_lin;Direções de embarcações pesqueiras (CCMA-PCM_2022);CCMA-PCM;nome;Sim
48;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Aspectos Socieconômicos Costeiro e Marinho;Cartografia Social do Mar;1200_CE_infraestruturas_pesqueiras_2022_pto;Infraestruturas pesqueiras (CCMA-PCM_2022);CCMA-PCM;nome;
49;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Aspectos Socieconômicos Costeiro e Marinho;Cartografia Social do Mar;1200_CE_naufragios_avancos_mar_2022_pto;Naufrágios e avanços do mar (CCMA-PCM_2022);CCMA-PCM;nome;
50;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Aspectos Socieconômicos Costeiro e Marinho;Cartografia Social do Mar;1200_CE_pesqueiros_artesanais_2022_pto;Pontos de Pesca Artesanal (CCMA-PCM_2022);CCMA-PCM;nome;
51;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Aspectos Socieconômicos Costeiro e Marinho;Cartografia Social do Mar;1200_CE_tradicao_religiosidade_turismo_2022_lin;Tradições, religiosidade e turismo (CCMA-PCM_2022);CCMA-PCM;nome;Sim
52;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Caracterização Geográfica;Municípios Costeiros;1200_CE_municipios_costeiros_2021_pol;Municípios Costeiros (IPECE_2021);IPECE;municipio;Sim
53;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Economia do Mar;Agências de viagens;1200_CE_ativ_econ_agencias_viagem_2021_pol;Renda - Agências viagens (RAIS_2021);RAIS;ano_2019;Sim
54;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Economia do Mar;Alojamentos;1200_CE_ativ_econ_alojamentos_2021_pol;Renda - Alojamentos (RAIS_2021);RAIS;ano_2019;Sim
55;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Economia do Mar;Artefatos de pesca e esporte;1200_CE_ativ_econ_pesca_esporte_2021_pol;Renda - Artefatos de pesca (RAIS_2021);RAIS;ano_2019;Sim
56;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Economia do Mar;Atividades imobiliárias de imóveis próprios;1200_CE_ativ_econ_imob_proprios_2021_pol;Renda - Imóveis próprios (RAIS_2021);RAIS;ano_2019;Sim
57;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Economia do Mar;Economia imobiliária;1200_CE_ativ_econ_imobiliarias_2021_pol;Renda - Economia imobiliária (RAIS_2021);RAIS;ano_2019;Sim
58;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Economia do Mar;Hoteis e similares;1200_CE_ativ_econ_hoteis_similares_2021_pol;Renda - Rede hoteleira (RAIS_2021);RAIS;ano_2019;Sim
59;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Economia do Mar;Negócios imobiliários;1200_CE_ativ_econ_compra_venda_aluguel_2021_pol;Renda - Negócios imobiliários (RAIS_2021);RAIS;ano_2019;Sim
60;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Economia do Mar;Operadores turísticos;1200_CE_ativ_econ_operadores_turismo_2021_pol;Renda - Operadores turistícos (RAIS_2021);RAIS;ano_2019;Sim
61;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Economia do Mar;Produção do pescado;1200_CE_ativ_econ_pescado_2021_pol;Renda - produção pescado (RAIS_2021);RAIS;ano_2020;Sim
62;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Economia do Mar;Restaurantes;1200_CE_ativ_econ_restaurantes_2021_pol;Renda - Restaurantes (RAIS_2021);RAIS;ano_2019;Sim
63;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Economia do Mar;Serviços ambulantes de alimentação;1200_CE_ativ_econ_ambulantes_alimentacao_2021_pol;Renda - Ambulantes Alimentação (RAIS_2021);RAIS;ano_2019;Sim
64;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Batimetria;1200_CE_batimetria_2014_lin;Batimetria plataforma continental (CPRM/ANP_2014);CPRM/ANP;profundida;
65;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Extração de areia;1200_CE_banco_areia_2019_pol;Bancos de areia (IBGE_2019);IBGE;tipobanco;
66;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Faciologia da Plataforma;1200_CE_facies_sedimentares_2021_pol;Fácies sedimentares (PGGM_2021);PGGM;classes;
67;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Geologia e Geofísica Marinha;1200_CE_areas_interesse_extracao_mineral_2008_pol;Áreas de interesse extração mineral (REMPLAC_2008);REMPLAC;substancia;
68;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Geologia e Geofísica Marinha;1200_CE_estruturas_geo_oceanicas_2008_lin;Estruturas geológicas oceânicas (REMPLAC_2008);REMPLAC;estrutura;
69;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Geologia e Geofísica Marinha;1200_CE_padroes_granulometricos_2008_pol;Padrões granulométricos (REMPLAC_2008);REMPLAC;classes;
70;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Geologia e Geofísica Marinha;1200_CE_padroes_sedimentares_2014_pol;Padrões sedimentares (PRONEX_2014);PRONEX;padrao_sed;
71;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Geologia e Geofísica Marinha;1200_CE_tipo_fundo_geologia_2021_pto;Geologia de fundo oceânico (CHM_BNDO_2021);CHM;tipo_fundo;Sim
72;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Geomorfologia Marinha;1200_CE_fisiografia_marinha_2021_pol;Fisiografia marinha (REMPLAC-LEPLAC_2021/GEBCO_2022);REMPLAC-LEPLAC;toponimo_2;
73;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Litologia Costeira;1200_CE_litologia_costeira_2021_pol;Litologia Costeira (CPRM_2021);CPRM;nome_unida;Sim
74;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Mineração (atual e futura);1200_CE_atividade_mineracao_2021_pol;Atividades mineração (ANM_2021);ANM;subs;Sim
75;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Geologia e Geomorfologia Costeira e Marinha;Mineração (atual e futura);1200_CE_potencial_explo_granulados_2018_pto;Exploração de granulados (PRONEX_2018);PRONEX;teor_caco3;
76;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;História do Ceará: Costa e Mar;Áreas de naufrágios;1200_CE_naufragios_2015_pto;Naufrágios (Atlas Naufrágios_2015);Atlas Naufrágios;Simbologia não categorizada por atributo;
77;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;História do Ceará: Costa e Mar;Arqueologia costeira;1200_CE_sitios_cnsa_iphan_2006_pol;Sítios arqueológicos (IPHAN_2006);IPHAN;Simbologia não categorizada por atributo;
78;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;História do Ceará: Costa e Mar;Arqueologia costeira;1200_CE_sitios_cnsa_iphan_2006_pto;Sítios arqueológicos - Pontos (IPHAN_2006);IPHAN;ds_classif;Sim
79;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Infraestrutura;Estruturas de proteção costeira - Tipologia;1200_CE_obras_protecao_costeira_2021_pto;Obras de proteção costeira (CCMA-PCM_2021);CCMA-PCM;Simbologia não categorizada por atributo;
80;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Alvos das Áreas Prioritárias;1200_CE_aves_alvos_ap_2018_pol;Aves - alvos de Áreas Prioritárias (MMA_2018);MMA;just_alvo;Sim
81;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Alvos das Áreas Prioritárias;1200_CE_ecossistemas_costeiros_alvos_ap_2018_pol;Ecossistemas Costeiros - alvos (MMA_2018);MMA;nome_ecos;Sim
82;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Alvos das Áreas Prioritárias;1200_CE_ecossistemas_marinhos_alvos_ap_2018_pol;Ecossistemas Marinhos - alvos (MMA_2018);MMA;just_meta;Sim
83;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Alvos das Áreas Prioritárias;1200_CE_exercicios_priorizacao_alvos_ap_2018_pol;Exercícios de priorização - alvos (MMA_2018);MMA;just_alvo;Sim
84;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Alvos das Áreas Prioritárias;1200_CE_habitats_alvos_ap_2018_pol;Habitats - alvos de Áreas Prioritárias (MMA_2018);MMA;just_alvo;Sim
85;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Alvos das Áreas Prioritárias;1200_CE_ictiofauna_alvos_ap_2018_pol;Ictiofauna - alvos de Áreas Prioritárias (MMA_2018);MMA;nome_popul;Sim
86;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Alvos das Áreas Prioritárias;1200_CE_ilhas_alvos_ap_2018_pol;Ilhas - alvos de Áreas Prioritárias (MMA_2018);MMA;just_alvo;
87;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Alvos das Áreas Prioritárias;1200_CE_invertebrados_alvos_ap_2018_pol;Invertebrados - alvos de Áreas Prioritárias (MMA_2018);MMA;nome_pop;
88;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Alvos das Áreas Prioritárias;1200_CE_mamiferos_alvos_ap_2018_pol;Mamíferos - alvos de Áreas Prioritárias (MMA_2018);MMA;just_alvo;Sim
89;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Alvos das Áreas Prioritárias;1200_CE_quelonios_alvos_ap_2018_pol;Quelônios - alvos de Áreas Prioritárias (MMA_2018);MMA;just_meta;Sim
90;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Área PEM Ceará;1200_CE_planej_espacial_marinho_2021_pol;Área PEM (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
91;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Áreas Híbridas;1200_CE_areas_hibridas_2018_pol;Áreas Híbridas (MMA_2018);MMA;acaop_p;Sim
92;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Áreas prioritárias e Protegidas;1200_CE_areas_prioritarias_marinhas_2018_pol;Áreas prioritárias conservação biodiversidade (MMA, 2018);MMA, 2018;imp;Sim
93;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Limites Jurisdicionais;1200_CE_limites_jurisdicionais_12milhas_2018_lin;Limites 12 milhas (CHM-BNDO, 2018);CHM-BNDO, 2018;Simbologia não categorizada por atributo;
94;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Limites Jurisdicionais;1200_CE_limites_jurisdicionais_200milhas_2018_lin;Limites 200 milhas (CHM-BNDO, 2018);CHM-BNDO, 2018;Simbologia não categorizada por atributo;
95;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Limites Jurisdicionais;1200_CE_limites_jurisdicionais_24milhas_2018_lin;Limites 24 milhas (CHM-BNDO, 2018);CHM-BNDO, 2018;Simbologia não categorizada por atributo;
96;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Linha de base;1200_CE_linha_de_base_2019_lin;Linha de Base (DHN_2019);DHN;Simbologia não categorizada por atributo;
97;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Terras Indígenas e Quilombolas;1200_CE_areas_quilombolas_2021_pol;Áreas de Quilombolas (INCRA_2021);INCRA;nm_comunid;Sim
98;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Instrumentos Legais;Terras Indígenas e Quilombolas;1200_CE_terras_indigenas_2018_pol;Terras indígenas (FUNAI_2018);FUNAI;terrai_nom;Sim
99;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Processos Costeiros e Marinhos;Oceanografia Física;1200_CE_corrente_fundo_BNDO_2022_pto;Correntes - Profundidade > 100m (BNDO-CHM_2022);BNDO-CHM;projeto;Sim
100;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Processos Costeiros e Marinhos;Oceanografia Física;1200_CE_corrente_sup_BNDO_2022_pto;Correntes - Profundidade < 100m (BNDO-CHM_2022);BNDO-CHM;projeto;Sim
101;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Processos Costeiros e Marinhos;Oceanografia Física;1200_CE_densidade_fundo_BNDO_2022_pto;Densidade - Profundidade > 100m (BNDO-CHM_2022);BNDO-CHM;projeto;Sim
102;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Processos Costeiros e Marinhos;Oceanografia Física;1200_CE_densidade_sup_BNDO_2022_pto;Densidade - Profundidade < 100m (BNDO-CHM_2022);BNDO-CHM;projeto;Sim
103;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Processos Costeiros e Marinhos;Oceanografia Física;1200_CE_salinidade_fundo_BNDO_2022_pto;Salinidade - Profundidade > 100m (BNDO-CHM_2022);BNDO-CHM;projeto;Sim
104;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Processos Costeiros e Marinhos;Oceanografia Física;1200_CE_salinidade_sup_BNDO_2022_pto;Salinidade - Profundidade < 100m (BNDO-CHM_2022);BNDO-CHM;projeto;Sim
105;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Processos Costeiros e Marinhos;Oceanografia Física;1200_CE_temperatura_fundo_BNDO_2022_pto;Temperatura - Profundidade > 100m (BNDO-CHM_2022);BNDO-CHM;projeto;Sim
106;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Processos Costeiros e Marinhos;Oceanografia Física;1200_CE_temperatura_sup_BNDO_2022_pto;Temperatura - Profundidade < 100m (BNDO-CHM_2022);BNDO-CHM;projeto;Sim
107;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Química de Ambientes Costeiros e Marinhos;Anomalias Geoquímicas;1200_CE_anomalias_geoquimicas_1985_pto;Anomalias Geoquímicas (REMPLAC_1985);REMPLAC;element;
108;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Riscos Costeiros e Marinhos;Áreas de Risco Geológico;1200_CE_areas_risco_geologico_2012_pol;Áreas de Risco Geológico (CPRM_2012);CPRM;tipolo_g1;Sim
109;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Riscos Costeiros e Marinhos;Erosão Costeira;1200_CE_panorama_erosao_costeira_2017_pto;Panorama de erosão costeira (MMA/PGGM_2018);MMA/PGGM;situacao;
110;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Riscos Costeiros e Marinhos;Ocorrências de manchas de óleo;1200_CE_ocorrencias_oleo_19_20_2019_pto;Ocorrências de manchas de óleo (CCMA_2019 a 2020);CCMA;setor;
111;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Riscos Costeiros e Marinhos;Ocorrências de manchas de óleo;1200_CE_ocorrencias_oleo_1semestre_2022_pto;Ocorrências de manchas de óleo (CCMA_2022-1º Semestre);CCMA;setor;
112;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Riscos Costeiros e Marinhos;Ocorrências de manchas de óleo;1200_CE_ocorrencias_oleo_2semestre_2022_pto;Ocorrências de manchas de óleo (CCMA_2022-2º Semestre);CCMA;setor;
113;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Riscos Costeiros e Marinhos;Sensibilidade à manchas de óleo;1200_ce_isl_estrategico_2004_lin;Classificação estratégica dos índices de sensibilidade do litoral (2024);MMA;Classe;
114;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Riscos Costeiros e Marinhos;Sensibilidade à manchas de óleo;1200_ce_isl_operacional_2004_lin;Classificação operacional dos índices de sensibilidade do litoral (2024);MMA;Legenda;
115;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Riscos Costeiros e Marinhos;Sensibilidade à manchas de óleo;1200_ce_isl_tatico_2004_lin;Classificação tática dos índices de sensibilidade do litoral (2024);MMA;Legenda;
116;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Riscos Costeiros e Marinhos;Postos de guarda vidas;1200_CE_postos_guarda_vidas_2022_pto;Postos de guarda vidas (CBMCE_2022);CBMCE;Simbologia não categorizada por atributo;
117;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Área militar, união;1200_CE_area_militar_farois_2021_pto;Faróis de navegação (DHN_2021);DHN;Simbologia não categorizada por atributo;
118;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Áreas de banho de mar e lazer;1200_CE_banho_mar_2021_pto;Pontos balneares (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
119;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Áreas de fundeio;1200_CE_fundeios_2004_pto;Pontos de ancoragem (MMA_2004);MMA;Simbologia não categorizada por atributo;
120;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_altinha_2021_pto;Esportes - Altinha (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
121;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_beach_boxing_2021_pto;Esportes naúticos - Beach boxing (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
122;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_bodyboarding_2021_pto;Esportes naúticos - Bodyboard (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
123;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_buggy_2021_pto;Prática - Buggy (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
124;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_caiaque_2021_pto;Esportes naúticos - Caiaque (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
125;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_caminhada_2021_pto;Esportes naúticos - Caminhada (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
126;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_canoagem_2021_pto;Esportes naúticos - Canoagem (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
127;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_cavalgada_2021_pto;Prática - Cavalgada (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
128;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_ciclismo_2021_pto;Prática - Ciclismo (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
129;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_corrida_2021_pto;Prática - Corrida (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
130;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_esqui_aquatico_2021_pto;Esportes náuticos - Esqui aquático (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
131;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_frescobol_2021_pto;Prática - Frescobol (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
132;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_frisbee_2021_pto;Prática - Frisbee (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
133;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_futebol_americano_2021_pto;Prática - Futebol Americano (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
134;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_futebol_de_areia_2021_pto;Prática - Futebol de Areia (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
135;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_futevolei_2021_pto;Prática - Futevolei (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
136;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_futmesa_2021_pto;Prática - Futmesa (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
137;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_handbol_2021_pto;Prática - Handbol (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
138;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_kitesurf_2021_pto;Esportes naúticos - Kitesurf (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
139;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_mergulho_2021_pto;Prática - Mergulho (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
140;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_moto_aquatica_2021_pto;Prática - Moto Aquática (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
141;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_natacao_2021_pto;Prática - Natação (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
142;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_parapente_2021_pto;Prática - Parapente (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
143;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_patinacao_2021_pto;Prática - Patinação (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
144;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_quadriciclo_2021_pto;Prática - Quadriciclo (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
145;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_remo_2021_pto;Esportes naúticos - Remo (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
146;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_rugbi_2021_pto;Esportes naúticos - Rugbi (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
147;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_sandboard_2021_pto;Esportes naúticos - sandboard (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
148;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_skate_2021_pto;Esportes naúticos - skate (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
149;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_skimboard_2021_pto;Esportes naúticos - skimboard (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
150;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_slackline_2021_pto;Esportes naúticos - slackline (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
151;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_standup_paddle_2021_pto;Esportes naúticos - Standup paddle (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
152;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_surf_2021_pto;Esportes naúticos - Surf (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
153;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_tenis_praia_2021_pto;Esportes naúticos - tênis de praia (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
154;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_treino_funcional_2021_pto;Prática - Treino Funcional (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
155;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_triatlon_2021_pto;Prática - Triatlon (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
156;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_trilhas_2021_pto;Prática - Trilhas (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
157;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_vela_2021_pto;Esportes naúticos - Vela (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
158;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_volei_2021_pto;Prática - Volei (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
159;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_windsurf_2021_pto;Esportes naúticos - Windsurf (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
160;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Atividades esportivas;1200_CE_yoga_2021_pto;Prática - Yoga (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
161;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Cabos e tubulações submarinas;1200_CE_cabos_submarino_2021_lin;Cabos Submarinos (GITHUB_2021);GITHUB;name;
162;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Cabos e tubulações submarinas;1200_CE_tubulacoes_dessalinizacao_2021_lin;Tubulações Dessalinização (CAGECE_2021);CAGECE;nome;Sim
163;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Cabos e tubulações submarinas;1200_CE_tubulacoes_emissarios_2021_lin;Tubulações Emissários (CAGECE_2021);CAGECE;nome;Sim
164;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Dutos;1200_CE_historico_blocos_ofertados_epe_2020_pol;Blocos de petróleo e gás (EPE_2020);EPE;atrat_merc;Sim
165;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Estações maregráficas;1200_CE_estacoes_maregraficas_2022_pto;Estações Maregráficas (CHM_BNDO_2022);CHM;Simbologia não categorizada por atributo;Sim
166;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Estaleiros;1200_CE_estaleiros_2021_pol;Estaleiros (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
167;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Instalações portuárias;1200_CE_instalacoes_portuarias_2021_pol;Instalações Portuárias (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
168;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Instalações portuárias;1200_CE_portos_2021_pto;Portos marítmos (MINFRA_2021);MINFRA;Simbologia não categorizada por atributo;Sim
169;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Potencial para atividades de mergulho;1200_CE_mergulho_autonomo_2017_pto;Mergulho autônomo (Atlas Naufrágios_2017);Atlas Naufrágios;Simbologia não categorizada por atributo;
170;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Potencial para atividades de mergulho;1200_CE_mergulho_livre_2021_pto;Mergulho livre (Atlas Naufrágios_2017);Atlas Naufrágios;Simbologia não categorizada por atributo;
171;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Potencial para geração de energia marinha / costeira;1200_CE_aerogeradores_offshore_2022_pto;Aerogeradores offshore - em licenciamento (IBAMA_2022);IBAMA;nome;Sim
172;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Potencial para geração de energia marinha / costeira;1200_CE_aerogeradores_onshore_2019_pto;Aerogeradores onshore (ANEEL_2019);ANEEL;Simbologia não categorizada por atributo;
173;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Potencial para geração de energia marinha / costeira;1200_CE_cabos_eolicas_offshore_2022_lin;Cabeamentos eólicas offshore (IBAMA_2022);IBAMA;nome;Sim
174;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Potencial para geração de energia marinha / costeira;1200_CE_usina_eolica_offshore_2022_pol;Usinas eólicas offshore - em licenciamento (IBAMA_2022);IBAMA;nome;Sim
175;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial de marinas e garagens náuticas;1200_CE_marinas_garagens_nauticas_2021_pol;Marinas e garagens naúticas (CCMA_2021);CCMA;Simbologia não categorizada por atributo;Sim
176;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial de pesca;1200_CE_currais_pesca_2015_pto;Currais de pesca (UECE_2015);UECE;Simbologia não categorizada por atributo;
177;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial de pesca;1200_CE_desembarque_lagosta_2021_pto;Desembarque de lagosta (Labomar-UFC_2021);Labomar-UFC;Simbologia não categorizada por atributo;
178;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial de pesca;1200_CE_lagosta_2021_pto;Lagosta (UFC/UECE_2010-2015);UFC/UECE;Simbologia não categorizada por atributo;Sim
179;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial de pesca;1200_CE_lagostas_2021_pol;Lagosta Espécies Fases (CCMA_2021);CCMA;especie;Sim
180;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial de pesca;1200_CE_pesca_artesanal_2020_pto;Pesca artesanal (Labomar-UFC_2020);Labomar-UFC;Simbologia não categorizada por atributo;Sim
181;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial para Aquicultura e Maricultura;1200_CE_algicultura_2007_pol;Algicultura (SAP_2007);SAP;Simbologia não categorizada por atributo;
182;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial para Aquicultura e Maricultura;1200_CE_cartas_SAO_aquicultura_2004_pto;Cartas SAO - Aquicultura (MMA_2004);MMA;Simbologia não categorizada por atributo;
183;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial para Aquicultura e Maricultura;1200_CE_fazendas_camarao_2007_pto;Carcinicultura (SAP_2007);SAP;Simbologia não categorizada por atributo;
184;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial para Aquicultura e Maricultura;1200_CE_maricultura_2015_pol;Maricultura (UECE_2015);UECE;Simbologia não categorizada por atributo;
185;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial para Aquicultura e Maricultura;1200_CE_ocorrencia_ostra_2007_pto;Cultivo de ostras (SAP_2007);SAP;Simbologia não categorizada por atributo;
186;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial para Aquicultura e Maricultura;1200_CE_piscicultura_2007_pol;Piscicultura (SAP_2007);SAP;Simbologia não categorizada por atributo;
187;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial para extração de Petróleo e Gás;1200_CE_plataformas_petroleo_2019_pto;Plataformas de petróleo (MB_2019);MB;Simbologia não categorizada por atributo;
188;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial para o Turismo Náutico;1200_CE_area_potencial_turismo_nautico_2021_pol;Áreas de turismo naútico (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
189;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial para o Turismo Náutico;1200_CE_barco_passeio_2021_pto;Passeios de barco (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
190;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial para o Turismo Náutico;1200_CE_passeio_jangada_2021_pto;Passeio de jangada (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
191;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Presença e potencial para usinas de dessalinização;1200_CE_usinas_dessalinizacao_2021_pol;Usinas dessalinização (Prefeituras_2021);Prefeituras;Simbologia não categorizada por atributo;Sim
192;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Rotas marítimas;1200_CE_linhas_cabotagem_2017_lin;Linhas de cabotagem (MINFRA_2017);MINFRA;Simbologia não categorizada por atributo;Sim
193;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Rotas marítimas;1200_CE_rotas_embarcacoes_2021_lin;Rotas embarcações (CCMA_2019);CCMA;Simbologia não categorizada por atributo;
194;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Rotas turísticas;1200_CE_rotas_turisticas_2021_lin;Rotas turísticas (CCMA_2021);CCMA;Simbologia não categorizada por atributo;Sim
195;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Terminais de container;1200_CE_terminal_containers_2021_pol;Terminais containers (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
196;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Usos e Atividades;Usina de Hidrogênio verde;1200_CE_hidrogenio_verde_2021_pto;Usinas hidrogênio verde (CCMA_2021);CCMA;Simbologia não categorizada por atributo;
197;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Vida Costeira e Marinha;Ambientes recifais;1200_CE_recifes_naturais_2017_pto;Recifes Naturais (Labomar-UFC_2017);Labomar-UFC;ponto;
198;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Vida Costeira e Marinha;Áreas e rotas de Aves;1200_CE_aves_limicolas_2021_pto;Aves Limícolas (AQUASIS_2021);AQUASIS;Simbologia não categorizada por atributo;Sim
199;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Vida Costeira e Marinha;Áreas e rotas de Cetáceos;1200_CE_encalhes_cetaceos_2016_pto;Encalhes Cetáceos (SIMMAM_2016);SIMMAM;Simbologia não categorizada por atributo;Sim
200;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Vida Costeira e Marinha;Áreas e rotas de Sirênios;1200_CE_encalhes_sirenios_2016_pto;Encalhes Sirênios (SIMMAM_2016);SIMMAM;Simbologia não categorizada por atributo;Sim
201;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Vida Costeira e Marinha;Áreas espécies exóticas;1200_CE_especies_exoticas_2022_pto;Áreas espécies exóticas (Labomar-UFC_2022);Labomar-UFC;Simbologia não categorizada por atributo;
202;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Vida Costeira e Marinha;Desova e berçário;1200_CE_ninhos_tartaruga_marinha_2021_pto;Ninhos de Tartarugas Marinhas (VERDELUZ_2021);VERDELUZ;Simbologia não categorizada por atributo;
203;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Vida Costeira e Marinha;Larvas ictioplancton;1200_CE_ictioplancton_larvas_2017_pto;Larvas ictioplancton (Labomar-UFC_2017);Labomar-UFC;Simbologia não categorizada por atributo;
204;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Vida Costeira e Marinha;Ovos ictioplancton;1200_CE_ictioplancton_ovos_2017_pto;Ovos ictioplancton (Labomar-UFC_2017);Labomar-UFC;Simbologia não categorizada por atributo;
205;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Vida Costeira e Marinha;Potencial para o Carbono Azul;1200_CE_veg_mangue_2018_pol;Vegetação mangue (IBAMA_2018);IBAMA;Simbologia não categorizada por atributo;Sim
206;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Vida Costeira e Marinha;Presença de algas calcáreas;1200_CE_algas_calcareas_bentonicas_2004_pto;Algas calcáreas bentônicas (UFRGS_2004);UFRGS;Simbologia não categorizada por atributo;Sim
207;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Vida Costeira e Marinha;Zooplancton Foco Copepoda;1200_CE_zooplancton_copepoda_2017_pto;Zooplancton Foco Copepoda (Labomar-UFC_2017);Labomar-UFC;Simbologia não categorizada por atributo;
208;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Certificação Praia Limpa;;1206_CE_certificacao_plimpa_2010_pol;Municípios certificados - 1º Edição (SEMA_2010);SEMA;classifica;
209;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Certificação Praia Limpa;;1206_CE_certificacao_plimpa_2018_pto;Municípios certificados - 2º, 3º e 4º Edição (SEMA_2018);SEMA;edic4_2018;
210;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Programa Selo Verde;;1206_CE_comdema_2021_pol;Condema (SEMA_2021);SEMA;comdemas;
211;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Programa Selo Verde;;1206_CE_selo_verde_2004_2010_pol;Selo Verde (SEMA_2010);SEMA;cert_2010;
212;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Programa Selo Verde;;1206_CE_selo_verde_2011_2022_pol;Selo Verde (SEMA_2022);SEMA;cert_2022;
213;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Projeto Orla;;1206_CE_municipios_pgi_orla_maritima_2021_pol;Municípios com Plano de Gestão Integrada da Orla Marítima - PGI (SEMA_2021);SEMA;gestao_pra;
214;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Projeto Orla;;1206_CE_praias_por_municipio_2021_lin;Linhas limite localização praias (SEMA_2021);SEMA;municipio;Sim
215;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Projeto Orla;;1206_CE_praias_por_municipio_2021_pto;Pontos de localização praias (SEMA_2021);SEMA;municipio;
216;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Projeto Orla;;1206_CE_projeto_orla_cascavel_2020_pol;Trechos/Zonas da orla marítima de Cascavel (SEMA_2020);SEMA;trecho;Sim
217;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Projeto Orla;;1206_CE_projeto_orla_caucaia_2018_pol;Trechos/Zonas da orla marítima de Caucaia (IMAC_2018);IMAC;trecho;Sim
218;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Projeto Orla;;1206_CE_projeto_orla_itapipoca_2020_pol;Unidades da orla marítima de Itapipoca (IMMI_2020);IMMI;unidade;Sim
219;Instrumentos e Projetos Territoriais;Outros Programas, Projetos e Planos Institucionais;Projeto Orla;;1206_CE_projeto_orla_itapipoca_trecho_2020_pol;Trechos/Zonas da orla marítima de Itapipoca (IMMI_2020);IMMI;trecho;Sim
220;Instrumentos e Projetos Territoriais;Plano de Manejo;APA do Rio Pacoti;;1205_CE_zoneamento_uc_pacoti_2022_pol;Zoneamento da UC (SEMA_2023);SEMA;zon_manejo;Sim
221;Instrumentos e Projetos Territoriais;Plano de Manejo;PE do Cocó;;1205_CE_areas_prioritarias_inclusao_uc_pecoco_2020_pol;Areas prioritarias inclusao (SEMA_2020);SEMA;nome_area;Sim
222;Instrumentos e Projetos Territoriais;Plano de Manejo;PE do Cocó;;1205_CE_drenagem_uc_pecoco_2020_lin;Drenagem bacia(SEMA_2020);SEMA;tip_drenag;Sim
223;Instrumentos e Projetos Territoriais;Plano de Manejo;PE do Cocó;;1205_CE_edificacoes_uc_pecoco_2020_pto;Edificações (SEMA_2020);SEMA;Simbologia não categorizada por atributo;
224;Instrumentos e Projetos Territoriais;Plano de Manejo;PE do Cocó;;1205_CE_infraestruturas_uc_pecoco_2020_pol;Infraestruturas da UC (SEMA_2020);SEMA;nome_infra;Sim
225;Instrumentos e Projetos Territoriais;Plano de Manejo;PE do Cocó;;1205_CE_massas_dagua_uc_pecoco_2020_pol;Massas dagua (SEMA_2020);SEMA;Simbologia não categorizada por atributo;
226;Instrumentos e Projetos Territoriais;Plano de Manejo;PE do Cocó;;1205_CE_monitor_quali_agua_uc_pecoco_2020_pto;Monitor qualidade agua (SEMA_2020);SEMA;nome_local;
227;Instrumentos e Projetos Territoriais;Plano de Manejo;PE do Cocó;;1205_CE_uso_solo_uc_pecoco_2020_pol;Uso e Ocupação do Solo(SEMA_2020);SEMA;classe;Sim
228;Instrumentos e Projetos Territoriais;Plano de Manejo;PE do Cocó;;1205_CE_zona_amortecimento_uc_pecoco_2020_pol;Zona de amortecimento (SEMA_2020);SEMA;nome_zonas;
229;Instrumentos e Projetos Territoriais;Plano de Manejo;PE do Cocó;;1205_CE_zoneamento_aquatico_uc_pecoco_2020_pol;Zoneamento da UC - aquático (SEMA_2020);SEMA;zon_manejo;Sim
230;Instrumentos e Projetos Territoriais;Plano de Manejo;PE do Cocó;;1205_CE_zoneamento_terrestre_uc_pecoco_2020_pol;Zoneamento da UC - terrestre (SEMA_2020);SEMA;zon_manejo;Sim
231;Instrumentos e Projetos Territoriais;Plano de Manejo;PEM da Pedra da Risca do Meio;;1205_CE_areas_prioritarias_uc_PEMPRIM_2019_pol;Áreas Prioritarias Manejo (SEMA_2019);SEMA;Simbologia não categorizada por atributo;
232;Instrumentos e Projetos Territoriais;Plano de Manejo;PEM da Pedra da Risca do Meio;;1205_CE_curvas_batimetricas_uc_PEMPRIM_2019_lin;Curvas batimétricas (SEMA_2019);SEMA;cota;
233;Instrumentos e Projetos Territoriais;Plano de Manejo;PEM da Pedra da Risca do Meio;;1205_CE_morfologia_fundo_uc_PEMPRIM_2019_pol;Morfologia de fundo (SEMA_2019);SEMA;nome;Sim
234;Instrumentos e Projetos Territoriais;Plano de Manejo;PEM da Pedra da Risca do Meio;;1205_CE_pontos_mergulho_uc_PEMPRIM_2019_pto;Pontos de Mergulho (SEMA_2019);SEMA;Simbologia não categorizada por atributo;
235;Instrumentos e Projetos Territoriais;Plano de Manejo;PEM da Pedra da Risca do Meio;;1205_CE_subs_rochoso_uc_PEMPRIM_2019_pol;Substrato rochoso (SEMA_2019);SEMA;Simbologia não categorizada por atributo;
236;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Area Planicie Litoranea;1201_CE_areatotal_planice_litoranea_2021_pol;Área Planicie Litoranea (SEMA_2021);SEMA;Simbologia não categorizada por atributo;
237;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Áreas Urbanas;1201_CE_zeec_areas_urbanas_2022_pol;Áreas Urbanas (MUNICÍPIOS_2022);MUNICÍPIOS;Simbologia não categorizada por atributo;Sim
238;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Cartografia Social;1201_CE_ativ_econom_2021_pto;Atividades Economicas Pontos (SEMA_2021);SEMA;nome;
239;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Cartografia Social;1201_CE_territorialidades_zeec_2021_pol;Territorialidades Poligonos (SEMA_2021);SEMA;nome;Sim
240;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Cartografia Social;1201_CE_territorialidades_zeec_2021_pto;Territorialidades Pontos (SEMA_2021);SEMA;nome;
241;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Diagnóstico;1201_CE_diagnostico_biotico_2019_pol;Biótico (SEMA_2019);SEMA;especie;Sim
242;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Diagnóstico;1201_CE_diag_dominio_natural_2019_pol;Geoambiental Domínio Natural (SEMA_2019);SEMA;dominio_nt;Sim
243;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Diagnóstico;1201_CE_zeec_diagnostico_p_litora_2021_lin;Geoambiental Planície Litorânea - linha (SEMA_2022);SEMA;setor_ambi;Sim
244;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Diagnóstico;1201_CE_diag_tabuleiro_2021_lin;Geoambiental Tabuleiros e Sistemas Interiores - linha (SEMA_2022);SEMA;setor_ambi;Sim
245;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Diagnóstico;1201_CE_zeec_uso_ocupacao_2021_pol;Uso e Ocupação (SEMA_2021);SEMA;uso_ocup;Sim
246;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Diagnóstico;1201_CE_zeec_geoambiental_tabuleiro_sistemas_in_2021_pol;Geoambiental Tabuleiros e Sistemas Interiores - polígono (SEMA_2022);SEMA;setor_ambi;Sim
247;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Diagnóstico;1201_CE_zeec_diagnostico_p_litora_2021_pol;Geoambiental Planície Litorânea - polígono (SEMA_2022);SEMA;setor_ambi;Sim
248;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Municípios Costeiros;1201_CE_limites_setor1_2019_pol;Limites Setor I (IPECE_2019);IPECE;Simbologia não categorizada por atributo;Sim
249;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Municípios Costeiros;1201_CE_limites_setor2_2019_pol;Limites Setor II (IPECE_2019);IPECE;Simbologia não categorizada por atributo;Sim
250;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Municípios Costeiros;1201_CE_limites_setor3_2019_pol;Limites Setor III (IPECE_2019);IPECE;Simbologia não categorizada por atributo;Sim
251;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Municípios Costeiros;1201_CE_limites_setor4_2019_pol;Limites Setor IV (IPECE_2019);IPECE;Simbologia não categorizada por atributo;Sim
252;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Zoneamento;1201_CE_zeec_zoneamento_p_litora_2021_lin;ZEEC Planície Litorânea - linha (SEMA_2022);SEMA;setor_ambi;Sim
253;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Zoneamento;1201_CE_zeec_zoneamento_p_litora_2021_pol;ZEEC Planície Litorânea - polígono (SEMA_2022);SEMA;sub_zonas;Sim
254;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Zoneamento;1201_CE_zeec_tabuleiro_sistemas_interiores_2021_lin;ZEEC Tabuleiros e Sistemas Interiores - linha (SEMA_2022);SEMA;setor_ambi;Sim
255;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Zoneamento;1201_CE_zeec_tabuleiro_sistemas_interiores_2021_pol;ZEEC Tabuleiros e Sistemas Interiores - polígono (SEMA_2022);SEMA;sub_zonas;Sim
256;Instrumentos e Projetos Territoriais;Plano Classificação Falésias;;;1207_CE_plano_class_falesias_2024_lin;Plano Classificação Falésias (SEMA_2024);SEMA;tipologia;Sim
257;Recuperação Ambiental;Produção de mudas;;;1000_CE_mudas_viveiros_estaduais_2021_pto;Unidades produção de mudas - viveiros estaduais (SEMA_2021);SEMA;viveiro;
258;Recuperação Ambiental;Recuperação de danos ambientais;;;1001_CE_tcr_danos_ambientais_2024;Termos de Compromisso de Recuperação de Danos Ambientais (SEMACE_2024);SEMACE;ativ_visto;Sim
259;Recuperação Ambiental;Reflorestamento;;;1002_CE_areas_florestamento_reflorestamento_2021_pto;Áreas de Florestamento e Reflorestamento (SEMA_2021);SEMA;instituica;
260;Saneamento Básico;Resíduos Sólidos;Consórcios;;1303_CE_consorcios_manejo_residuos_2021_pol;Consórcios públicos de manejo dos resíduos sólidos (SEMA_2021);SEMA;consorcio;Sim
261;Saneamento Básico;Resíduos Sólidos;Disposição Final de Resíduos;;1303_CE_aterros_sanitario_2022_pto;Aterros Sanitários (SEMA_2022);SEMA;municipio;
262;Saneamento Básico;Resíduos Sólidos;Disposição Final de Resíduos;;1303_CE_destino_final_lixoes_2022_pto;Destino Final Lixões (SEMA_2022);SEMA;municipio;
263;Saneamento Básico;Resíduos Sólidos;Logistica Reversa;;1303_CE_logistica_reversa_2017_pto;Logística Reversa (SEMA_2017);SEMA;posto;
264;Saneamento Básico;Resíduos Sólidos;Programa Auxílio Catador;;1303_CE_programa_auxilio_catadores_2021_pol;Associações Catadores por município (SEMA_2021);SEMA;associacao;Sim
265;Sistemas de Transportes;Aeroportos;;;0400_CE_aerodromos_2018_pto;Aerodromos (MINFRA_2018);MINFRA;Simbologia não categorizada por atributo;
266;Sistemas de Transportes;Ferrovias;;;0401_CE_ferrovias_2018_lin;Ferrovias (MINFRA_2018);MINFRA;Simbologia não categorizada por atributo;Sim
267;Sistemas de Transportes;Rodovias;;;0402_CE_rodovias_estaduais_2015_lin;Rodovias estaduais (IPECE_2015);IPECE;tipo_rodov;Sim
268;Sistemas de Transportes;Rodovias;;;0402_CE_rodovias_federais_2015_lin;Rodovias federais (IPECE_2015);IPECE;nome_rodov;Sim
269;Território;Limites;;;0100_CE_limites_municipais_2021_pol;Limites Municipais (IPECE_2021);IPECE;Simbologia não categorizada por atributo;Sim
270;Território;Localidades;;;0100_CE_comunidades_entorno_2022_pto;Comunidades Entorno da UC (SEMA_2022);SEMA;nome_uc;
271;Território;Regionalizações;;;0102_CE_regioes_de_planejamento_2018_pol;Regiões de Planejamento do Estado do Ceará  (IPECE_2018);IPECE;regiao;Sim
272;Fiscalização Ambiental;Fiscalização;;;0200_CE_termo_embargo_ibama_2021_pol;Termo de Embargo (IBAMA_2021);IBAMA;Simbologia não categorizada por atributo;
273;Biodiversidade;Áreas protegidas;;;0604_CE_rppn_2023_pol;RPPN (CEUC/SEMA_2023);CEUC/SEMA;grupo;Sim;
274;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Riscos Costeiros e Marinhos;Erosão Costeira;1200_CE_nucleos_erosao_costeira_2024_pto;Núcleos de erosão costeira (SEMA/CCMA_2024);SEMA/CCMA;Simbologia não categorizada por atributo;
275;Instrumentos e Projetos Territoriais;Atlas Digital Costeiro e Marinho do Ceará;Riscos Costeiros e Marinhos;Erosão Costeira;1200_CE_panorama_erosao_costeira_2024_lin;Panorama de erosão costeira (SEMA/CCMA_2024);SEMA/CCMA;classes;
276;Instrumentos e Projetos Territoriais;Zoneamentos;Zoneamento Ecológico-Econômico da Zona Costeira;Zoneamento;1201_CE_zeec_zoneamento_p_litora_2021_pol ;ZEEC Planície Litorânea - polígono (SEMA_2022);SEMA;sub_zonas;Sim;
277;Instrumentos e Projetos Territoriais;Plano de Manejo;Monólitos Quixada;;1205_CE_zona_amortecimento_uc_quixada_2022_pol;Zona de amortecimento  (SEMA_2022);SEMA;Simbologia não categorizada por atributo;
278;Instrumentos e Projetos Territoriais;Plano de Manejo;Monólitos Quixada;;1205_CE_zoneamento_uc_quixada_2022_pol;Zoneamento da UC (SEMA_2022);SEMA;tipo_zona;`;

export default zcmData;