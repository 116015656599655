import React from 'react';
import './HeavyLink.css';


const HeavyLink = (props) => {

  return (
      <a className={props.btClass+" HeavyLink"} href={props.href} target='_blank' rel="noreferrer">{props.content}</a>
    );
}


export default HeavyLink;
