import React, { useState, useEffect } from 'react';
import './StoryTellingModal.css';
import { Chart } from 'chart.js/auto';
import modal_logo from '../../../../_assets/img/modal_logo.png'
import modal_logo_min from '../../../../_assets/img/modal_logo_min.png'
import { Modal } from 'react-bootstrap';
import jsPDF from 'jspdf';


var sections;

const StoryTellingModal = ({ active, callback, map }) => {

  const clientWidth = document.documentElement.clientWidth;

  const layers = [...map.getLayers().getArray()];

  const [layerOptions, setLayerOptions] = useState([]);

  const [charterWidth, setCharterWidth] = useState([]);

  const [charterHeight, setCharterHeight] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  useEffect(() => {
    if (active)
      handleShow();
  }, [active])

  useEffect(() => {
    callback(show)
  }, [show])

  useEffect(() => {
    buildLayerSelect();
    sections = [];
    //handleSectionMenuVisibility();
  }, [])

  /** INTERFACE */
  const buildLayerOption = (layer, id) => {

    return (<option key={id} value={layer.get('wmsName').split(':')[1]}>{layer.get(`title`)}</option>);

  }

  function buildLayerSelect() {
    const layerOptions = layers
      .filter(layer => layer.get('isAllowedToStatistics'))
      .map((layer, key) => buildLayerOption(layer, key));

    return setLayerOptions(layerOptions);

  }

  function loadAttributes() {

    const layerName = document.getElementById('layerName').value;

    if (!(layerName))
      return;


    const url = `https://pedea.sema.ce.gov.br/api/v1/attributes/layer?layer=${layerName}`;

    showLoader(true);

    fetch(url)
      .then(response => response.text())
      .then(data => {
        const attributes = data.match(/[\w]{1,}:/g).map(attr => attr.slice(0, -1));
        const attributeTypes = data.match(/[\w]{1,};/g).map(type => type.slice(0, -1));
        populateAttributes(attributes, attributeTypes);
        showLoader(false);
      })
      .catch(error => {
        console.error('Erro ao carregar os atributos:', error);
        showLoader(false);
      });
  }

  function populateAttributes(attributes, attributeTypes) {

    const layerName = document.getElementById('layerName').value;

    if (!(layerName))
      return;

    let fieldX = document.getElementById('fieldX');
    let fieldY = document.getElementById('fieldY');
    let fieldZ = document.getElementById('fieldZ');

    fieldX.innerHTML = '';
    fieldY.innerHTML = '';
    fieldZ.innerHTML = '';

    attributes.forEach((attr, index) => {
      const type = attributeTypes[index];
      if (isTextualOrDate(type) || attr.includes("ano")) {
        const optionX = document.createElement('option');
        optionX.value = attr;
        optionX.textContent = attr;
        fieldX.appendChild(optionX);
      }
      if (isNumericOrDate(type)) {
        const optionY = document.createElement('option');
        optionY.value = attr;
        optionY.textContent = attr;
        fieldY.appendChild(optionY);

        const optionZ = document.createElement('option');
        optionZ.value = attr;
        optionZ.textContent = attr;
        fieldZ.appendChild(optionZ);
      }
    });
  }

  function isTextualOrDate(type) {
    return ['character', 'varchar', 'text', 'date', 'timestamp'].some(t => type.startsWith(t));
  }

  function isNumericOrDate(type) {
    return ['integer', 'int', 'float', 'double', 'numeric', 'date', 'timestamp'].some(t => type.startsWith(t));
  }

  /** GERADOR DE SECOES */

  function addSection(event) {

    event.preventDefault();

    const layerName = document.getElementById('layerName').value;

    if (!(layerName))
      return;

    const sectionType = document.getElementById('sectionType').value;
    const sectionTitle = document.getElementById('sectionTitle').value;
    const sectionDescription = document.getElementById('sectionDescription').value;
    const sectionId = Date.now(); // Para garantir um ID único para cada seção

    const sectionContainer = document.createElement('div');
    sectionContainer.id = `section-${sectionId}`;
    sectionContainer.classList.add('section-container', 'mb-4');

    let sectionHtml = '';

    if (sectionTitle) {
      sectionHtml += `<h3>${sectionTitle}</h3>`;
    }

    if (sectionDescription) {
      sectionHtml += `<p>${sectionDescription}</p>`;
    }

    sectionHtml += sectionType === `graphResult` ? `<div id="chartContainer-${sectionId}"></div>` : `<div id="result-${sectionId}"></div>`;

    sectionContainer.innerHTML = sectionHtml;

    document.getElementById('storyTellingSections').appendChild(sectionContainer);

    sections.push(sectionId);

    // Chama a função de geração de resultados baseada no tipo de seção escolhida
    switch (sectionType) {
      case 'groupBy':
        generateGroupBy(sectionId);
        break;
      case 'customQuery':
        generateCustomQuery(sectionId);
        break;
      case 'percentResult':
        generatePercentResult(sectionId);
        break;
      case 'countResult':
        generateCountResult(sectionId);
        break;
      case 'averageResult':
        generateAverageResult(sectionId);
        break;
      case 'graphResult':
        generateGraphResult(sectionId);
        break;
      default:
        console.error('Tipo de seção inválido.');
    }

    if (sections.length > 0) {
      toggleButtons(false);
    } else {
      toggleButtons(true);
    }

  }

  function toggleButtons(disable) {

    if (disable) {
      document.getElementById("exportSection").style.display = `none`;
      document.getElementById("removeSection").style.display = `none`;
    } else {
      document.getElementById("exportSection").style.display = `flex`;
      document.getElementById("removeSection").style.display = `flex`;
    }

  }

  function exportToPDF(event) {

    event.preventDefault();

    const pdf = new jsPDF();
    const storyTellingSections = document.getElementById('storyTellingSections');

    pdf.setFontSize(24);
    pdf.text("PEDEA - Relatório personalizado", 45, 10); 

    const sections = storyTellingSections.getElementsByClassName('section-container');
    let yOffset = 20; 

    Array.from(sections).forEach(section => {
      const title = section.querySelector('h3')?.textContent || '';
      const description = section.querySelector('p')?.textContent || '';
      const table = section.querySelector('table');
      const chartCanvas = section.querySelector('canvas');

      if (title) {
        pdf.setFontSize(20);
        pdf.text(title, 10, yOffset);
        yOffset += 10;
      }

      if (description) {
        pdf.setFontSize(14);
        pdf.text(description, 10, yOffset);
        yOffset += 10;
      }

      if (table) {
        const rows = table.querySelectorAll('tr');
        rows.forEach((row, rowIndex) => {
          const cells = row.querySelectorAll('td, th');
          cells.forEach((cell, cellIndex) => {
            const text = cell.textContent;
            pdf.text(text, 10 + (cellIndex * 50), yOffset + (rowIndex * 10));
          });
        });
        yOffset += rows.length * 10;
      }

      if (chartCanvas) {
        const chartImage = chartCanvas.toDataURL('image/png');
        pdf.addImage(chartImage, 'PNG', 10, yOffset, 180, 90); // Ajusta a posição e o tamanho do gráfico
        yOffset += 100;  // Ajuste para adicionar espaço após o gráfico
      }

      yOffset += 20;  // Espaço entre seções
    });

    pdf.save('storyTelling.pdf');
  }

  function removeSection(event) {
    event.preventDefault();
    let n = sections.length;
    if (n > 0) {
      const section = document.getElementById(`section-${sections[n - 1]}`);
      section?.remove();
      sections.pop();
    }
  }

  function handleSectionMenuVisibility() {
    const sectionType = document.getElementById('sectionType').value;
    const fieldX = document.getElementById('fieldXSection');
    const fieldY = document.getElementById('fieldYSection');
    const fieldZ = document.getElementById('fieldZSection');
    const operatorSection = document.getElementById('operatorSection');
    const chartType = document.getElementById('chartTypeSection');

    // Reseta visibilidade
    fieldX.style.display = 'block';
    fieldY.style.display = 'block';
    fieldZ.style.display = 'none';
    operatorSection.style.display = 'none';
    chartType.style.display = 'none';

    switch (sectionType) {
      case 'graphResult':
        fieldX.style.display = 'block';
        fieldY.style.display = 'block';
        fieldZ.style.display = 'block';
        operatorSection.style.display = 'flex';
        chartType.style.display = 'block';
        break;
      case 'customQuery':
        fieldX.style.display = 'block';
        fieldY.style.display = 'block';
        fieldZ.style.display = 'block';
        operatorSection.style.display = 'flex';
        break;
      case 'percentResult':
      case 'countResult':
      case 'averageResult':
        // Apenas campo Z com operador e valor, sem gráficos
        fieldX.style.display = 'none';
        fieldY.style.display = 'none';
        chartType.style.display = 'none';
        fieldZ.style.display = 'block';
        operatorSection.style.display = 'flex';
        break;
    }
  }


  /** GRAFICOS */

  function generateRandomColors(numColors) {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      colors.push(`rgb(${r}, ${g}, ${b})`);
    }
    return colors;
  }

  function generateGraphResult(sectionId) {

    document.getElementById('filterValue').value = ``;

    const fieldZ = document.getElementById('fieldZ').value;
    const operator = document.getElementById('operator').value;
    const filterValue = document.getElementById('filterValue').value;

    let filter = fieldZ && operator && filterValue ? `&CQL_FILTER=${fieldZ}%20${operator}%20'${filterValue}'` : ``;

    const layerName = document.getElementById('layerName').value;
    const fieldX = document.getElementById(`fieldX`).value;
    const fieldY = document.getElementById(`fieldY`).value;
    const chartType = document.getElementById('chartType').value;
    const url = `https://pedea.sema.ce.gov.br/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&outputFormat=application/json` + filter;


    showLoader(true);

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const xValues = [];
        const yValues = [];

        data.features.forEach(feature => {
          xValues.push(feature.properties[fieldX]);
          yValues.push(feature.properties[fieldY]);
        });


        const aggregatedData = aggregateData(xValues, yValues);

        if (aggregatedData.xValues.length <= 1 && chartType !== 'pie')
          renderNonSection(sectionId, true);
        else
          renderGraphResult(chartType, aggregatedData.xValues, aggregatedData.yValues, fieldX, fieldY, sectionId);

        showLoader(false);
      })
      .catch(error => {
        console.error('Erro ao carregar os dados:', error);
        showLoader(false);
      });
  }

  function renderGraphResult(chartType, xValues, yValues, fieldX, fieldY, sectionId) {

    const sizeConstant = 80;

    const pieSizeConstant = 600;

    const resultContainer = document.getElementById(`chartContainer-${sectionId}`);

    resultContainer.innerHTML = `<canvas id="charter-${sectionId}"></canvas>`;

    const ctx = document.getElementById(`charter-${sectionId}`).getContext('2d');

    const backgroundColors = generateRandomColors(xValues.length);
    const borderColors = backgroundColors.map(color => color.replace('0.2', '1'));

    setCharterWidth(chartType === 'pie' ? pieSizeConstant : xValues.length * sizeConstant);
    setCharterHeight(chartType === 'pie' ? pieSizeConstant / 2 : (xValues.length * sizeConstant) / 2);

    document.getElementById("chartContainer-" + sectionId).style.width = chartType === 'pie' ? pieSizeConstant : `${xValues.length * sizeConstant}px`;
    document.getElementById("chartContainer-" + sectionId).style.height = chartType === 'pie' ? pieSizeConstant / 2 : `${(xValues.length * sizeConstant) / 2}px`;

    window.myChart = new Chart(ctx, {
      type: chartType,
      data: {
        labels: xValues,
        datasets: [{
          label: `${fieldY} por ${fieldX}`,
          data: yValues,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            display: chartType !== 'pie'
          },
          x: {
            display: chartType !== 'pie',
            ticks: {
              font: {
                size: 10 // Ajuste o tamanho da fonte aqui
              },
              autoSkip: false, // Para não pular labels
              maxRotation: 60, // Para manter os labels na vertical
              minRotation: 60  // Para manter os labels na vertical
            }
          }
        }
      }
    });

  }

  /** TABELAS */

  function generateGroupBy(sectionId) {
    const layerName = document.getElementById('layerName').value;
    const fieldX = document.getElementById(`fieldX`).value;
    const fieldY = document.getElementById(`fieldY`).value;
    const url = `https://pedea.sema.ce.gov.br/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&outputFormat=application/json`;

    showLoader(true);

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const xValues = [];
        const yValues = [];

        data.features.forEach(feature => {
          xValues.push(feature.properties[fieldX]);
          yValues.push(feature.properties[fieldY]);
        });

        const aggregatedData = sortAggregatedData(aggregateData(xValues, yValues));

        if (aggregatedData.xValues.length < 1)
          renderNonSection(sectionId);
        else
          renderTableResultGroupBy(sectionId, aggregatedData.xValues, aggregatedData.yValues, fieldX, fieldY);


        showLoader(false);
      })
      .catch(error => {
        console.error('Erro ao carregar os dados:', error);
        showLoader(false);
      });
  }

  function renderTableResultGroupBy(sectionId, xValues, yValues, fieldX, fieldY) {
    let resultContainer = document.getElementById(`result-${sectionId}`);
    let tableHtml = `
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>${fieldX}</th>
            <th>${fieldY}</th>
          </tr>
        </thead>
        <tbody>
    `;

    xValues.forEach((x, index) => {
      tableHtml += `
        <tr>
          <td>${x}</td>
          <td>${yValues[index]}</td>
        </tr>
      `;
    });

    tableHtml += `
        </tbody>
      </table>
    `;

    resultContainer.innerHTML = tableHtml;
  }

  function generateCustomQuery(sectionId) {
    const fieldZ = document.getElementById('fieldZ').value;
    const operator = document.getElementById('operator').value;
    const filterValue = document.getElementById('filterValue').value;
    const layerName = document.getElementById('layerName').value;
    const fieldX = document.getElementById(`fieldX`).value;
    const fieldY = document.getElementById(`fieldY`).value;
    const url = `https://pedea.sema.ce.gov.br/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&outputFormat=application/json&CQL_FILTER=${fieldZ}%20${operator}%20'${filterValue}'`;

    showLoader(true);

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const xValues = [];
        const yValues = [];

        data.features.forEach(feature => {
          xValues.push(feature.properties[fieldX]);
          yValues.push(feature.properties[fieldY]);
        });

        const aggregatedData = sortAggregatedData(aggregateData(xValues, yValues));

        console.log(aggregatedData)

        if (aggregatedData.xValues.length < 1)
          renderNonSection(sectionId);
        else
          renderTableResultGroupBy(sectionId, aggregatedData.xValues, aggregatedData.yValues, fieldX, fieldY);

        showLoader(false);
      })
      .catch(error => {
        console.error('Erro ao carregar os dados:', error);
        showLoader(false);
      });
  }

  /** RESULTADOS SINGLE */

  function generatePercentResult(sectionId) {
    const fieldZ = document.getElementById('fieldZ').value;
    const operator = document.getElementById('operator').value;
    const filterValue = document.getElementById('filterValue').value;
    const layerName = document.getElementById('layerName').value;

    const url = `https://pedea.sema.ce.gov.br/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&outputFormat=application/json`;

    showLoader(true);


    fetch(url)
      .then(response => response.json())
      .then(data => {
        const totalRecords = data.features.length;
        const filteredRecords = data.features.filter(feature => {
          const value = feature.properties[fieldZ];
          return evaluateCondition(value, operator, filterValue);
        }).length;

        const percentage = (filteredRecords / totalRecords) * 100;

        renderPercentResult(percentage, sectionId);
        showLoader(false);

      })
      .catch(error => {
        console.error('Erro ao carregar dados do WFS:', error);
        showLoader(false);
      });
  }

  function renderPercentResult(percentage, sectionId) {
    const resultContainer = document.getElementById(`result-${sectionId}`);
    resultContainer.innerHTML = `<p>${percentage.toFixed(2)}% dos registros atendem à condição especificada.</p>`;
  }

  function generateCountResult(sectionId) {
    const fieldZ = document.getElementById('fieldZ').value;
    const operator = document.getElementById('operator').value;
    const filterValue = document.getElementById('filterValue').value;
    const layerName = document.getElementById('layerName').value;

    const url = `https://pedea.sema.ce.gov.br/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&outputFormat=application/json`;
    showLoader(true);
    fetch(url)
      .then(response => response.json())
      .then(data => {
        const count = data.features.filter(feature => {
          const value = feature.properties[fieldZ];
          return evaluateCondition(value, operator, filterValue);
        }).length;

        renderCountResult(count, sectionId);
        showLoader(false);
      })
      .catch(error => {
        console.error('Erro ao carregar dados do WFS:', error);
        showLoader(false);
      });
  }

  function renderCountResult(count, sectionId) {
    const resultContainer = document.getElementById(`result-${sectionId}`);
    resultContainer.innerHTML = `<p>Total de ${count} registros que atendem à condição especificada.</p>`;
  }

  function generateAverageResult(sectionId) {
    const fieldZ = document.getElementById('fieldZ').value;
    const operator = document.getElementById('operator').value;
    const filterValue = document.getElementById('filterValue').value;
    const layerName = document.getElementById('layerName').value;

    const url = `https://pedea.sema.ce.gov.br/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&outputFormat=application/json`;
    showLoader(true);
    fetch(url)
      .then(response => response.json())
      .then(data => {
        const filteredRecords = data.features.filter(feature => {
          const value = feature.properties[fieldZ];
          return evaluateCondition(value, operator, filterValue);
        });

        const total = filteredRecords.reduce((sum, feature) => sum + feature.properties[fieldZ], 0);
        const average = total / filteredRecords.length;

        renderAverageResult(average, fieldZ, sectionId);
        showLoader(false);
      })
      .catch(error => {
        console.error('Erro ao carregar dados do WFS:', error);
        showLoader(false);
      });
  }

  function renderAverageResult(average, fieldZ, sectionId) {
    const resultContainer = document.getElementById(`result-${sectionId}`);
    resultContainer.innerHTML = `<p>A média simples de ${fieldZ} considerando os registros que atendem à condição especificada é ${average.toFixed(2)}.</p>`;
  }

  /** AUXILIARES */

  function renderNonSection(sectionId, isGraphic = false) {
    const resultContainer = isGraphic ? document.getElementById(`chartContainer-${sectionId}`) : document.getElementById(`result-${sectionId}`);
    resultContainer.innerHTML = `<p>A consulta não retornou dados suficientes para construir a seção desejada.</p>`;
  }

  function evaluateCondition(value, operator, filterValue) {
    switch (operator) {
      case '=':
        return value == filterValue;
      case '!=':
        return value != filterValue;
      case '>':
        return value > filterValue;
      case '<':
        return value < filterValue;
      case '>=':
        return value >= filterValue;
      case '<=':
        return value <= filterValue;
      default:
        return false;
    }
  }

  function aggregateData(xValues, yValues) {
    let aggregatedData = {};
    xValues.forEach((x, index) => {
      if (!aggregatedData[x]) {
        aggregatedData[x] = 0;
      }
      aggregatedData[x] += yValues[index];
    });

    return {
      xValues: Object.keys(aggregatedData),
      yValues: Object.values(aggregatedData)
    };
  }

  function sortAggregatedData(aggregatedData) {
    const { xValues, yValues } = aggregatedData;

    // Combinar xValues e yValues em um array de pares
    const combinedData = xValues.map((xValue, index) => {
      return { x: xValue, y: yValues[index] };
    });

    // Ordenar o array com base nos valores de y (pode ser crescente ou decrescente)
    combinedData.sort((a, b) => b.y - a.y); // Para ordem decrescente, use b.y - a.y; para crescente, use a.y - b.y

    // Separar novamente os valores ordenados
    const sortedXValues = combinedData.map(item => item.x);
    const sortedYValues = combinedData.map(item => item.y);

    // Retornar os dados ordenados
    return {
      xValues: sortedXValues,
      yValues: sortedYValues
    };
  }

  function showLoader(show) {
    document.getElementById('loader').style.display = show ? 'block' : 'none';
  }

  return (

    <Modal className="StoryTellingModal" size={`xl`} show={show} onHide={handleClose} data-keyboard="false" data-backdrop="static">

      <img className="img-thumbnail" alt="Logo" title="Logo" src={clientWidth > 350 ? modal_logo : modal_logo_min} ></img>


      <Modal.Body >

        <div className="container mt-5">
          <h1 className="text-center">Story Telling de Dados</h1>

          <form id="StoryTellingModalCraftingForm" className="mt-4 StoryTellingModalCraftingForm">

            <div className="mb-3">
              <label htmlFor="layerName" className="form-label">Camada de referência</label>
              <select className="form-control" id="layerName" required onChange={loadAttributes}>
                <option value="">Selecione uma camada...</option>
                {layerOptions}
              </select>
            </div>

            <div className="mb3">
              <label className="form-label" htmlFor="sectionType">Tipo de Seção:</label>
              <select className="form-control" id="sectionType" onChange={handleSectionMenuVisibility}>
                <option value="groupBy">Ranqueamento</option>
                <option value="customQuery">Filtragem</option>
                <option value="percentResult">Resultado percentual</option>
                <option value="countResult">Resultado quantitativo</option>
                <option value="averageResult">Média simples</option>
                <option value="graphResult">Gráfico</option>
              </select>
            </div>

            <div className="mb3">
              <label className="form-label" htmlFor="sectionTitle">Título da Seção:</label>
              <input type="text" className="form-control" id="sectionTitle" placeholder="Informe o título da seção" />
            </div>

            <div className="mb3">
              <label className="form-label" htmlFor="sectionDescription">Descrição da Seção:</label>
              <textarea className="form-control" id="sectionDescription" rows="3" placeholder="Informe a descrição da seção"></textarea>
            </div>

            <div id="fieldXSection" className="mb-3">
              <label htmlFor="fieldX" className="form-label">Campo agregador</label>
              <select className="form-control" id="fieldX" required></select>
            </div>

            <div id="fieldYSection" className="mb-3">
              <label htmlFor="fieldY" className="form-label">Campo de dados</label>
              <select className="form-control" id="fieldY" required></select>
            </div>

            <div id="fieldZSection" className="mb3">
              <label className="form-label" htmlFor="fieldZ">Campo de filtro:</label>
              <select className="form-control" id="fieldZ"></select>
            </div>

            <div id="operatorSection" className="mb3">
              <label htmlFor="operator" className="form-label">Condição:</label>
              <select className="form-control" id="operator">
                <option value="=">Igual a</option>
                <option value=">"> Maior que</option>
                <option value="<"> Menor que</option>
                <option value=">="> Maior ou igual a</option>
                <option value="<="> Menor ou igual a</option>
                <option value="!=">Diferente de</option>
              </select>
              <input type="text" className="form-control" id="filterValue" placeholder="Informe o valor" />
            </div>

            <div id="chartTypeSection" className="mb-3">
              <label htmlFor="chartType" className="form-label">Tipo de Gráfico</label>
              <select className="form-control" id="chartType" required>
                <option value="line">Linha</option>
                <option value="bar">Barra</option>
                <option value="pie">Pizza</option>
              </select>
            </div>

            <div className="mb3 addSectionContainer">
              <button id="addSection" className="btn btn-primary" onClick={addSection}>Adicionar Seção</button>
              <button id="exportSection" className="btn btn-success" onClick={exportToPDF}>Exportar</button>
              <button id="removeSection" className="btn btn-danger" onClick={removeSection}>Remover Seção</button>
            </div>

          </form>

          <hr />
          <div id="storyTellingSections"></div>

          <div id="loader" className="text-center mt-3">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>

        </div>

      </Modal.Body>

      <Modal.Footer>

        <div className="row g-3">
          <div className="d-grid col-3">
            <button onClick={handleClose} className="btn btn-secondary btn-lg">Fechar</button>
          </div>
        </div>

      </Modal.Footer>

    </Modal>
  );

}


export default StoryTellingModal;