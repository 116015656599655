import React, { useRef } from 'react';
import './LinkToolbar.css';

const LinkToolbar = (props) => {

  const menuRef = useRef();

  const handleClick = () => {

    const element = menuRef.current;

    if(element.style.visibility ==='' || element.style.visibility === 'hidden'){

      element.style.visibility = 'visible';       

      element.style.opacity = '1';

      element.style.zIndex = '4';
      
     // element.style.display = 'block';

    }
    else{
      element.style.visibility = 'hidden';

      element.style.opacity = '0';

      element.style.zIndex = '0';

     // element.style.display = 'none';

    }

  }


  return (
    <div className="LinkToolbar">
      <a onClick={handleClick} className="LinkToolbarToggler" href={'#'} >☰</a>
      <div ref={menuRef} className="LinkToolbarDropdown">
          <div><a className="" href={'https://www.ceara.gov.br/'} target='_blank' rel="noreferrer">Portal do Governo</a></div>
          <div><a className="" href={'https://cearatransparente.ce.gov.br/'} target='_blank' rel="noreferrer">Ceará Transparente</a></div>
          <div><a className="" href={'https://cearatransparente.ce.gov.br/portal-da-transparencia/ouvidoria?locale=pt-BR'} target='_blank' rel="noreferrer">Ouvidoria</a></div>
          <div><a className="" href={'https://cearatransparente.ce.gov.br/portal-da-transparencia/acesso-a-informacao?locale=pt-BR'} target='_blank' rel="noreferrer">Acesso a informação</a></div>
      </div>
      <a className="LinkToolbarLink" href={'https://www.ceara.gov.br/'} target='_blank' rel="noreferrer">Portal do Governo</a>
      <a className="LinkToolbarLink" href={'https://cearatransparente.ce.gov.br/'} target='_blank' rel="noreferrer">Ceará Transparente</a>
      <a className="LinkToolbarLink" href={'https://cearatransparente.ce.gov.br/portal-da-transparencia/ouvidoria?locale=pt-BR'} target='_blank' rel="noreferrer">Ouvidoria</a>
      <a className="LinkToolbarLink" href={'https://cearatransparente.ce.gov.br/portal-da-transparencia/acesso-a-informacao?locale=pt-BR'} target='_blank' rel="noreferrer">Acesso a informação</a>
    </div>
  );
}


export default LinkToolbar;
