import './ResetZoom.css';
import React, { useEffect, useRef } from 'react';
import Zoom from 'ol/control/Zoom';
import {zoom, center} from '../../_config/map';
import restoreView_min from '../../_assets/img/restoreView_min.png';
import { vector } from '../../_config/layers/';

function ResetZoom(props) {

  const element = useRef();

  const restoreElement = useRef();

  useEffect( () => {

    const control = new Zoom({className: 'ol-zoom', target: element.current});
    
    props.map.addControl(control);
    
  },[])
 
  const handleClick = () =>{
    vector.getSource().clear();
    props.map.getView().setZoom(zoom);
    props.map.getView().setCenter(center);
    //props.map.un('singleclick',localStorage.getItem('singleclick'));
  }

  return (   
    <div className="ResetZoom">
      <button className='HorizontalButton'
        type='button'
        alt='Redimensionar zoom'
        title='Redimensionar zoom'
        onClick={handleClick}
        ref={restoreElement}
        >
        <i className="material-icons">zoom_out_map</i> {/* Ícone para redimensionar o zoom */}
      </button>
      <div ref={element} className="ResetZoomContent"> </div>
    </div>
  ) 

}

export default ResetZoom;