import { ServerTypeHelper} from '../helpers';
import zcmData from '../../_assets/data/zcm';
import geoCSVReader from '../../_assets/js/geoCSVReader';

const geoWorkspace= 'zcm';
const geoserverUrl = 'https://pedea.sema.ce.gov.br/geoserver/'; 
const serverType = ServerTypeHelper.GEOSERVER;

const {tree, layers} =  geoCSVReader(zcmData, geoWorkspace, geoserverUrl+geoWorkspace+'/wms', serverType);

document.getElementById('preloader').style.display = 'none';


export {layers, tree, geoWorkspace, geoserverUrl};