import React, { useEffect, useState } from 'react';
import LayerFolder from './components/LayerFolder';
import Folder from './components/Folder';
import HeavyLink from './components/HeavyLink';
import LayerScope from './components/LayerScope';
import LayerLink from './components/LayerLink';
import { ServerTypeHelper } from '../../_config/helpers';
import './LayerSwitcher.css';
import close from '../../_assets/img/close.png';

var folderIndex = 0;

const LayerSwitcher = (props) => {

  const title = 'Explorador de dados';

  const [layerTree, setLayerTree] = useState(null);

  useEffect( () => {

    setLayerTree(buildFolderTree(props.explorer));
    
  },[])

  const collapseLayerSwitcher = () => {

    const element = props.switcherRef.current;

    element.style.visibility = 'hidden';

    element.style.opacity = '0';

    element.style.zIndex = '0';

  }

  const buildFolderTree = (tree) => {

    return tree.map((folder, index) => {
      if (folder.subFolder == null) {
        folderIndex++;
        return (<LayerFolder hasNewVisibleLayer={props.hasNewVisibleLayer} folderSequence={folder.folderSequence} title={folder.title} map={props.map} folderIndex={folderIndex} key={'folder.' + folderIndex} />);
      }
      else {
        folderIndex++;
        return (<Folder title={folder.title} map={props.map} key={'folder.' + folderIndex} folderIndex={folderIndex}>{buildFolderTree(folder.subFolder, index)}</Folder>);
      }
    }
    )

  }

  const deactivateVisibleLayers = () => {

    for (let i = 0; i < props.map.getLayers().getArray().length; i++) {
      if (props.map.getLayers().getArray()[i].getVisible() && (props.map.getLayers().getArray()[i].get('serverType') && props.map.getLayers().getArray()[i].get('serverType') === ServerTypeHelper.GEOSERVER)) {
        props.map.getLayers().getArray()[i].setVisible(false);
        document.getElementById('layer' + i).checked = false;
        props.hasNewVisibleLayer(-i);
      }
    }

    return false;

  }

  return (
    <div ref={props.switcherRef} className={"LayerSwitcher"} id="navbarToggleExternalContent">
      
      <div className="p-4 LayerSwitcherExplorer">

        <div className='row'>
          <span className='LayerSwitcherToggler'>
          <button className='HorizontalButton'
              type='button'
              alt='Fechar explorador de camadas.'
              title='Fechar explorador de camadas.'
              onClick={collapseLayerSwitcher}>
              <i className="material-icons">close</i>
            </button>
          </span>
        </div>


        <div className='container'>
          <span className='LayerSwitcherTitle'>
            {title}
          </span>
          <br /><br />
          <LayerScope map={props.map}></LayerScope>
          
          <span className='LayerSwitcherTitle'>
            Categorias
          </span>
          
        </div>

        <div className='LayerTree container'>
          {layerTree}
        </div>


        <hr />

          <div className='container LayerSwitcherLinkWrapper'>
            <LayerLink content='Desativar camadas visíveis' callback={deactivateVisibleLayers} />
          </div>
          <div className='container HeavyLinkWrapper'>
            <HeavyLink btClass='btn btn-outline-light btn-lg' content='Baixar dados' href='https://pedea.sema.ce.gov.br/geoserver/web/wicket/bookmarkable/org.geoserver.web.demo.MapPreviewPage' />
          </div>

          
        {/*
        <div className='row'>
          <span className='LayerSwitcherTitle'>
            {heavyLinkListTitle} 
          </span> 
        </div>

        <br />

        <HeavyLink btClass='btn btn-outline-light btn-lg' content='Consultar site do Atlas' href='https://www.sema.ce.gov.br/89965-2/planejamento-costeiro-e-marinho-do-ceara/atlas-digital-costeiro-e-marinho-do-ceara/'  />

        <HeavyLink btClass='btn btn-outline-light btn-lg' content='Consultar site do Programa CCMA' href='https://www.sema.ce.gov.br/89965-2/'  />

        <HeavyLink btClass='btn btn-outline-light btn-lg' content='Consultar informações do ZEEC' href='https://www.sema.ce.gov.br/gerenciamento-costeiro/zoneamento-ecologico-economico-da-zona-costeira-zeec/documentos-previos-para-consulta-publica-do-zeec/'  />

        <HeavyLink btClass='btn btn-outline-light btn-lg' content='Vídeo de apresentação da plataforma' href='https://www.youtube.com/watch?v=CsfDQKaJJXc'  />

        <HeavyLink btClass='btn btn-outline-light btn-lg' content='Consultar manual do usuário' href='/portal/manual.pdf'  />

        */}


      </div>
    </div>
  );

}


export default LayerSwitcher;

