import React, { useState, useRef, useEffect } from 'react';
import './LayerFilterMin.css';
import { geoserverUrl, geoWorkspace, } from '../../../../_config/layers/zcm';
import TemplateModal from '../../../../components/TemplateModal/';
import { fromLonLat } from 'ol/proj';

var wfsData = []

const LayerFilterMin = (props) => {

  const wmsName = props.wmsName;

  const LayerFilterMinHandlerRef = useRef();

  const layerInputAttributeSelect = useRef();

  const layerInputOperatorSelect = useRef();

  const LayerFilterMinInput = useRef();

  const [layerOperators, setLayerOperators] = useState([]);

  const [layerAttributesOptions, setLayerAttributesOptions] = useState([]);

  const [layerAttributes, setLayerAttributes] = useState([]);

  const [layerAttributesTypes, setLayerAttributesTypes] = useState([]);

  const handleLayerFilterMinClick = (e) => {

    let display = (LayerFilterMinHandlerRef.current.style.display);

    if (display === 'none' || display === '') {

      if (layerAttributes.length == 0)
        fetchLayerAttributes();

      LayerFilterMinHandlerRef.current.style.display = 'block';
    } else {
      LayerFilterMinHandlerRef.current.style.display = 'none';
    }

  }

  const handleLayerFilterMinUpdaterClick = (e) => {

    let attribute = layerAttributes[(layerInputAttributeSelect.current.value)].replace(":", "");
    let attributeType = layerAttributesTypes[(layerInputAttributeSelect.current.value)];
    let operator = (layerInputOperatorSelect.current.value);
    let filterValue = formatFilterValue(attributeType, operator, LayerFilterMinInput.current.value);

    var filter = attribute + " " + operator + " " + filterValue;

    ///console.log(filter)
    // by default, reset all filters
    var filterParams = {
      'FILTER': null,
      'CQL_FILTER': null,
      'FEATUREID': null
    };

    if (filter.replace(/^\s\s*/, '').replace(/\s\s*$/, '') != "") {
      filterParams["CQL_FILTER"] = filter;
    }
    // merge the new filter definitions
    props.layer.getSource().updateParams(filterParams);

  }

  const formatFilterValue = (type, operator, filterValue) => {

    //console.log(type)

    if (type.search(`double`) !== -1 || type.search(`int`) !== -1 || type.search(`numeric`) !== -1 || type.search(`decimal`) !== -1 || type.search(`real`) !== -1 || type.search(`serial`) !== -1)
      return filterValue;
    else if (operator === `ilike`)
      return `'%${filterValue}%'`;
    else
      return `'${filterValue}'`;

  }

  const handleLayerFilterMinReseterClick = () => {

    var filterParams = {
      'FILTER': null,
      'CQL_FILTER': null,
      'FEATUREID': null
    };

    props.layer.getSource().updateParams(filterParams);

  }

  const fetchLayerAttributes = async () => {


    let method = 'GET'
    let url = 'https://pedea.sema.ce.gov.br/api/v1/attributes/layer?layer=' + wmsName.split(':')[1]
    let xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.onload = function () {

      let str = xhr.response;

      let attributes = str.match(/[\w]{1,}:/g);

      let attributesTypes = str.match(/[\w]{1,};/g);

      if (attributes && attributes[0] && attributesTypes && attributesTypes[0]) {
        try {
          setLayerAttributes(attributes);
          setLayerAttributesTypes(attributesTypes);
          setLayerOperators(buildLayerOperators());
        } catch (e) {
          console.log(e)
        }
      }
    };
    xhr.onerror = function () {
      console.log(xhr.response);
    };
    xhr.send();

  }

  const buildLayerAttributeOption = (attribute, id) => {

    attribute = attribute.replace(":", "");

    return (<option key={id} className={'LayerOperator'} value={id}>{attribute}</option>);

  }

  const buildLayerAttributeOptions = () => {

    return setLayerAttributesOptions(layerAttributes.map((attribute, key) => buildLayerAttributeOption(attribute, key)));

  }

  const buildLayerOperator = (operator, id) => {

    return (<option key={id} className={'LayerOperator'} value={operator[0]}>{operator[1]}</option>);

  }

  const buildLayerOperators = () => {

    const layerOperators = [[`=`, `Igual`], [`ilike`, `Contém`], [`<>`, `Diferente`], [`>`, `Maior`], [`>=`, `Maior igual`], [`<`, `Menor`], [`<=`, `Menor igual`]]

    return (layerOperators.map((operator, key) => buildLayerOperator(operator, key)));

  }

  useEffect(buildLayerAttributeOptions, [layerAttributes]);

  /** TABULAR FEATURES*/

  const [active, setActive] = useState(0);

  const [modalValue, setModalValue] = useState('');

  const handleLayerTabularFilterUpdaterClick = async (e) => {

    let attribute = layerAttributes[(layerInputAttributeSelect.current.value)].replace(":", "");
    let attributeType = layerAttributesTypes[(layerInputAttributeSelect.current.value)];
    let operator = (layerInputOperatorSelect.current.value);
    let filterValue = formatFilterValue(attributeType, operator, LayerFilterMinInput.current.value);

    var filter = attribute + " " + operator + " " + filterValue;

    //console.log(filter)
    // by default, reset all filters
    var filterParams = {
      'FILTER': null,
      'CQL_FILTER': null,
      'FEATUREID': null
    };

    if (filter.replace(/^\s\s*/, '').replace(/\s\s*$/, '') != "") {
      filterParams["CQL_FILTER"] = filter;
    }
    // merge the new filter definitions
    let wfsInfo = await requestWFSInfo(props.layer.get('wmsName').split(':')[1], filter);

    setModalValue([(<button className='btn-success btn' onClick={handleExportCSV}>Exportar</button>), wfsInfo]);

    setActive(active => active + 1);

  }

  const requestWFSInfo = async (layerName, filter) => {
    const url = `${geoserverUrl}/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&outputFormat=application/json&CQL_FILTER=${encodeURIComponent(filter)}`;
    wfsData = [];
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      wfsData.push(...data.features);

      return craftTable(data.features);
    } catch (error) {
      console.error('Error fetching WFS data:', error);
      return craftTable([]);
    }
  }

  const craftTable = (wfsData) => {

    if (wfsData.length === 0) {
      return <p>No data available</p>;
    }

    return (
      <table className="table">
        <thead>
          <tr>
            {Object.keys(wfsData[0].properties).map((key) => (
              <th key={key}>{key}</th>
            ))}
            <th>Localizador</th>
          </tr>
        </thead>
        <tbody>
          {wfsData.map((feature, index) => (
            <tr key={index}>
              {Object.values(feature.properties).map((value, i) => (
                <td key={i}>{value}</td>
              ))}
              <td>
                {/* Botão que dá zoom na feature no mapa */}
                <button className='btn-primary btn' onClick={() => zoomToFeature(props.map, feature)}>
                  Zoom
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  const zoomToFeature = (map, feature) => {
    const geometry = feature.geometry;
    const type = geometry.type;
    let coordinates = geometry.coordinates;

    if (type === 'Point') {
      // Para ponto, apenas converte a coordenada para EPSG:4326 e dá zoom
      const transformedCoordinates = fromLonLat(coordinates, 'EPSG:4326');
      map.getView().animate({
        center: transformedCoordinates,
        duration: 1000,
        zoom: 15
      });
    } else if (type === 'Polygon' || type === 'LineString' || type === 'MultiPolygon' || type === 'MultiLineString') {
      // Para Polygon, LineString, MultiPolygon e MultiLineString, transforma as coordenadas
      let transformedCoordinates = coordinates.map(coord => fromLonLat(coord, 'EPSG:4326'));

      // Para Polygon e MultiPolygon, transforma o primeiro anel de coordenadas (a borda externa)
      if (type === 'Polygon') {
        transformedCoordinates = transformedCoordinates[0]; // A borda externa do polígono
      } else if (type === 'MultiPolygon') {
        transformedCoordinates = transformedCoordinates.flatMap(polygon => polygon[0]); // A borda externa de todos os polígonos
      }

      // Para MultiLineString, precisamos aplainar o array de coordenadas
      if (type === 'MultiLineString') {
        transformedCoordinates = transformedCoordinates.flat();
      }

      console.log(fromLonLat(transformedCoordinates[0], 'EPSG:4326'))

      // Ajustar a visão do mapa para que toda a geometria fique visível
      try {
        props.map.getView().animate({
          center: fromLonLat(transformedCoordinates[0], 'EPSG:4326'),
          duration: 1000,
          zoom: 10
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  const convertToCSV = () => {
    console.log(wfsData)
    if (!wfsData || wfsData.length === 0) return '';

    const headers = Object.keys(wfsData[0].properties).join(';'); // Cabeçalhos do CSV
    const rows = wfsData.map(feature =>
      Object.values(feature.properties).join(';')
    ); // Linhas de dados
    return [headers, ...rows].join('\n'); // Junta os cabeçalhos e linhas
  }

  const downloadCSV = (csvData, fileName) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleExportCSV = () => {
    const csvData = convertToCSV();
    downloadCSV(csvData, `data.csv`);
  }

  return (
    <>
      <a className={"LayerFilterMin"} href='#' rel="noreferrer">
        <button className='HorizontalButton'
          type="button"
          onClick={handleLayerFilterMinClick}
          title="Filtrar camada"
          alt="Filtrar camada"
        >
          <i className="material-icons">filter_alt</i>
        </button>
      </a>
      <div id="LayerFilterMinHandler" ref={LayerFilterMinHandlerRef} className={"LayerFilterMinHandler"}>

        <span>Filtro: </span>

        <div className="input-group input-group-sm mb-3">
          <select ref={layerInputAttributeSelect} className={"form-select LayerFilterMinHandlerInput"}>
            <option value={``}>Atributo...</option>
            {layerAttributesOptions}
          </select>
        </div>

        <div className="input-group input-group-sm mb-3">
          <select ref={layerInputOperatorSelect} className={"form-select LayerFilterMinHandlerInput"}>
            <option value={``}>Operador...</option>
            {layerOperators}
          </select>
        </div>

        <div className="input-group input-group-sm mb-3">
          <input ref={LayerFilterMinInput} placeholder={"Ditie um valor..."} className={"form-control LayerFilterMinHandlerInput"}></input>
        </div>

        <div className="input-group input-group-sm mb-3">
          <button className="btn btn-secondary mb3" onClick={handleLayerFilterMinUpdaterClick} type="button" id="LayerFilterMinUpdater button-addon1">Filtrar</button>
          <span className="input-group-text"></span>
          <button className="btn btn-secondary mb3" onClick={handleLayerTabularFilterUpdaterClick} type="button" id="LayerFilterMinUpdater button-addon1">Tabular</button>
          <span className="input-group-text"></span>
          <button className="btn btn-secondary mb3" onClick={handleLayerFilterMinReseterClick} type="button" id="LayerFilterMinReseter button-addon1">Resetar</button>
        </div>

        <TemplateModal active={active} className="FeatureInfoModal" title={`Dados tabulares`}>{modalValue}</TemplateModal>


      </div>
    </>
  );
}

export default LayerFilterMin;
