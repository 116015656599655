import './FileUploader.css';
import React, { useState, useRef } from 'react';
import TemplateModal from '../../components/TemplateModal/TemplateModal';
import fileUploader_min from '../../_assets/img/fileUploader_min.png';
import { vector } from '../../_config/layers';
import CSVUploader from './components/CSVUploader/CSVUploader';
import KMLUploader from './components/KMLUploader';
import ShapeUploader from './components/ShapeUploader';

function FileUploader(props) {

  const htmlElement = useRef();

  const [active, setActive] = useState(0);

  const handleFileUploaderClick = () => {
    vector.getSource().clear();
    setActive(active => active + 1);
  }

  const closeModal = () => {
    setActive(active => false);
  }

  return (
    <div id="FileUploader" className="FileUploader">
      <button className='HorizontalButton'
        type='button'
        alt='Importar dados espaciais'
        title='Importar dados espaciais'
        onClick={handleFileUploaderClick}
        ref={htmlElement}
      >
        <i className="material-icons">file_upload</i> {/* Ícone para importar dados espaciais */}
      </button>
      <TemplateModal active={active} close={!active} title="Importar dados espaciais">
        <CSVUploader map={props.map} callback={closeModal} /> 
        <ShapeUploader map={props.map} callback={closeModal} />
        <KMLUploader map={props.map} callback={closeModal} />
      </TemplateModal>
    </div>
  )

}

export default FileUploader;