import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';


const source = new VectorSource();

const vector = new VectorLayer({
  source: source,
  style: new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
      color: '#ffcc33',
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: '#ffcc33',
      }),
    }),
  }),
});

const vectorForCSV = new VectorLayer({
  source: new VectorSource(),
  style: new Style({
    fill: new Fill({
      color: 'rgba(247, 149, 6, 0.7)',
    }),
    stroke: new Stroke({
      color: 'rgba(247, 149, 6, 1)',
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      stroke: new Stroke({
        color: 'rgba(247, 149, 6, 1)',
      }),
      fill: new Fill({
        color: 'rgba(247, 149, 6, 0.7)',
      }),
    }),
  }),
});

const vectorForGeolocation = new VectorLayer({
  source: new VectorSource(),
  style: new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: '#3399CC',
      }),
      stroke: new Stroke({
        color: '#000000',
        width: 2,
      }),
    }),
  }),
});

export { vector, vectorForCSV, vectorForGeolocation, source };