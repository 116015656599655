import './ShapeUploader.css';
import React, { useState, useRef, useEffect } from 'react';
import { SHPLoader } from '@loaders.gl/shapefile';
import { load } from '@loaders.gl/core';
import { Vector as VectorSource } from 'ol/source';
import { Vector } from 'ol/layer';
import { GeoJSON } from 'ol/format';
import { zoom, center } from '../../../../_config/map';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import Circle from 'ol/geom/Circle.js';

const image = new CircleStyle({
  radius: 5,
  fill: null,
  stroke: new Stroke({color: 'red', width: 1}),
});

const styles = {
  'Point': new Style({
    image: image,
  }),
  'LineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  'MultiLineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  'MultiPoint': new Style({
    image: image,
  }),
  'MultiPolygon': new Style({
    stroke: new Stroke({
      color: 'yellow',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 0, 0.1)',
    }),
  }),
  'Polygon': new Style({
    stroke: new Stroke({
      color: 'blue',
      lineDash: [4],
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
  'GeometryCollection': new Style({
    stroke: new Stroke({
      color: 'magenta',
      width: 2,
    }),
    fill: new Fill({
      color: 'magenta',
    }),
    image: new CircleStyle({
      radius: 10,
      fill: null,
      stroke: new Stroke({
        color: 'magenta',
      }),
    }),
  }),
  'Circle': new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(255,0,0,0.2)',
    }),
  }),
};

const styleFunction = function (feature) {
  return styles[feature.getGeometry().getType()];
};

var vectorForShape = null;

function ShapeUploader(props) {

  const htmlElement = useRef();

  const feedbackElement = useRef();

  const [file, setFile] = useState(undefined);

  const zIndexer = useState(props.map.getLayers().getArray().length);

  const reader = new FileReader();

  const handleChange = (e) => {

    const files = e.target.files;

    if (files) {

      setFile(files[0])

    }

  }

  const convertShapeToFeatures = async () => {

    try {
      if (!file) { showErrorFeedback(); return false; }

      //const data = await load(file, SHPLoader);

      handleReset();

      reader.onload = async function () {

        try {

          let result = (await load(reader.result, SHPLoader));

          console.log(result);

          const geojsonObjects = convertGeometriesToGeojson(result.geometries);
          
          const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonObjects),
          });
          //  let features = await parse(result, SHPLoader, { format: 'geojson' });

          let vector = new Vector({
            name: "SHP",
            source: vectorSource,
            style: styleFunction,

          });

          // vector.getSource().addFeatures(result);

          vector.setZIndex(zIndexer + 5);
          props.map.addLayer(vector);
          vectorForShape = (vector);
          let extent = vector.getSource().getExtent();
          props.map.getView().fit(extent, props.map.getSize());
          //  props.map.getView().setZoom(zoom);
          //  props.map.getView().setCenter(center);

        } catch (error) {
          console.log(error);
          showErrorFeedback();
        }

      }

      reader.readAsDataURL(file);

      props.callback();
    } catch (error) {
      console.log(error)
      showErrorFeedback();
    }

  }

  const convertGeometriesToGeojson = (data) => {

    let features = [];

    data.forEach((geometry, index) => features[index] = convertGeometryToFeature(geometry))

    return {
      'type': 'FeatureCollection',
      'crs': {
        'type': 'name',
        'properties': {
          'name': 'EPSG:4326',
        },
      },
      'features': features,
    };

  }

  const convertGeometryToFeature = (geometry) => {

    let coordinates = extractCoordinates(geometry.positions.value, geometry.type)

    return {
      'type': 'Feature',
      'geometry': {
        'type': geometry.type,
        'coordinates': coordinates,
      }
    }
  }

  const extractCoordinates = (coordinates, type) => {
    if(type==="LineString")
      return extractCoordinatesFromLineString(coordinates);
    else if(type === "Polygon")
      return extractCoordinatesFromPolygon(coordinates);
    else if(type === "Point")
      return coordinates;
    else
      throw new Error('Geometria invalida!');
  }

  const extractCoordinatesFromPolygon = (coordinates) => {
    let result = [];
    let i = 0;
    let j = 0;
    let w = 0;
    for(i=0;i<coordinates.length;i++){
      j++;
      if(j==8){
        result[w++] = [[coordinates[i-7],coordinates[i-6]],[coordinates[i-5],coordinates[i-4]],[coordinates[i-3],coordinates[i-2]],[coordinates[i-1],coordinates[i]]];
        j=0;
      }
    }
    return result;
  }

  const extractCoordinatesFromLineString = (coordinates) => {
    let result = [];
    let i = 0;
    let j = 0;
    let w = 0;
    for(i=0;i<coordinates.length;i++){
      j++;
      if(j==2){
        result[w++] = [coordinates[i-1],coordinates[i]];
        j=0;
      }
    }
    return result;
  }

  const handleReset = () => {
    if (vectorForShape) {
      props.map.removeLayer(vectorForShape);
      vectorForShape.getSource().clear();
      vectorForShape = undefined;

    }
  }

  const showErrorFeedback = () => {
    feedbackElement.current.style.visibility = "visible";
  }

  useEffect(convertShapeToFeatures, file)

  useEffect(() => { feedbackElement.current.style.visibility = "hidden"; }, [])

  return (
    <div className="ShapeUploader">

      <h6><label htmlFor="ShapeFormFile" className="form-label">Importar geometrias de um arquivo Shapefile </label></h6>

      <div className="input-group mb-3">
        <input ref={htmlElement} className="form-control" id="ShapeFormFile"
          type="file"
          accept=".zip,.shp"
          onChange={handleChange}
        />
        <span onClick={handleReset} className="input-group-text ShapeUploaderReset">Resetar</span>
      </div>

      <div className="ShapeUploaderHint">
        Os formatos geométricos suportados para importar um Shapefile (.shp) são: Point; LineString; e Polygon. Tipos como MultiPoint, MultiLineString, MultiPolygon, e GeometryCollection não são suportados.
      </div>

      <div ref={feedbackElement} className="ShapeUploaderErrorFeedback">
        Houve uma falha ao tentar importar seu arquivo Shapefile. Certifique-se de que o mesmo está no formato correto e tente novamente.
      </div>

    </div>
  )

}

export default ShapeUploader;