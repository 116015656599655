const LayerFolderHelper = {
    BASE: 'Imagens base',
    GOOGLE: 'Google',
    ESRI: 'ESRI',
    OSM: 'Open Street Maps',
    OL: 'Open Layers'
  }

const ServerTypeHelper = {
    GEOSERVER: 'geoserver',
    MAPSERVER: 'mapserver',
    OSM: 'osm',
    GOOGLE: 'google',
    ESRI: 'esri',
  }

  export {LayerFolderHelper, ServerTypeHelper};