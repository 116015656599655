import React, { useEffect, useRef, useState } from 'react';
import './Folder.css';
import openedFolder from '../../../../_assets/img/openedFolder.png';
import closedFolder from '../../../../_assets/img/closedFolder.png';


const Folder = (props) => {

  const content = useRef(null);

  const titleUnicode = useRef(null);

  const [toggle, setToggle] = useState(false);

  const handleFolderClick = () => {

    let layerFolderImage = (document.querySelector(`#folder${props.folderIndex}image`));
    
    layerFolderImage.style.color = '#FFFFFF';


    if (toggle) {
      content.current.style.visibility = 'hidden';

      content.current.style.display = 'none';

      content.current.style.opacity = '0';

      setToggle(false);
    } else {
      content.current.style.visibility = 'visible';

      content.current.style.display = 'inherit';

      content.current.style.opacity = '1';

      setToggle(true);
    }
  }

  const isScoped = () => {

    if (content.current.style.visibility === `visible`) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }

  return (
    <div onMouseOver={isScoped} className='LayerFolder' id={`folder${props.folderIndex}`}>
      <h3 className='LayerFolderTitle' onClick={handleFolderClick}>
        <span className='LayerFolderTitleIcon' ref={titleUnicode}>
          <i
            className="material-icons"
            alt="Pasta"
            ref={titleUnicode}
            id={`folder${props.folderIndex}image`}
          >
            {toggle ? "folder_open" : "folder"} {/* Ícone para pasta aberta ou fechada */}
          </i>
        </span>
        <span className='LayerFolderTitleText'>
          {props.title}
        </span>
      </h3>
      <div ref={content} className='content'>
        {props.children}
      </div>
    </div>
  );
}


export default Folder;
