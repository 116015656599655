import React, { useState, useEffect } from 'react';
import './WelcomeModal.css';
import modal_logo from '../../../../_assets/img/modal_logo.png'
import modal_logo_min from '../../../../_assets/img/modal_logo_min.png'
import { Modal } from 'react-bootstrap';


const WelcomeModal = ({ active, callback }) => {

  const clientWidth = document.documentElement.clientWidth;

  const bootstrapFooterCheckClass = clientWidth <= 1000 ? `row`:`col-9`;

  const bootstrapFooterButtonClass = clientWidth <= 1000 ? `row`:`col-3`;

  const modalSection1Content = `Esta é a Plataforma Estadual de Dados Espaciais Ambientais do Ceará (PEDEA-CE), ambiente virtual para você visualizar sistemas de dados espaciais já estruturados nos órgãos ambientais do Estado do Ceará.
  Antes de acessar e usar a plataforma, leia nossos Termos e Condições de Uso e o Aviso Legal.`;

  const modalSection2Content = `Os Termos e Condições de Uso trazem as regras, os direitos e as obrigações que você deverá obdecer quando acessar e usar a PEDEA. São, por isso, um acordo firmado entre você e a plataforma.`;

  const modalSection3Content = `A PEDEA não coleta nem trata nenhum dado pessoal. Ela apenas centraliza e integra as informações sobre a proteção ambiental do Estado do Ceará, dando cada vez mais transparência às políticas públicas e subsídios ao gestor público para verificar a realidade ambiental do estado.`;

  const [show, setShow] = useState(false);

  const [checkTermAndConditions, setCheckTermAndConditions] = useState(false);
  const [checkLegalWarning, setCheckLegalWarning] = useState(false);

  const handleClose = () => setShow(!(checkTermAndConditions && checkLegalWarning));
  const handleShow = () => setShow(true);


  useEffect(() => {
    if (active)
      handleShow();
  }, [active])

  useEffect(() => {
    callback(show)
  }, [show])

  useEffect(() => {

     if (sessionStorage.getItem(`FirstAccess`) === undefined ||
       sessionStorage.getItem(`FirstAccess`) === null) {
       sessionStorage.setItem(`FirstAccess`, true);
     }

     if (sessionStorage.getItem(`FirstAccess`) === `true`) {
        handleShow();
        sessionStorage.setItem(`FirstAccess`, false)
     }

  }, [])

  return (

    <Modal className="WelcomeModal" backdrop="static" keyboard={false} size={`xl`} show={show} onHide={handleClose} data-keyboard="false" data-backdrop="static">
      
      <img className="img-thumbnail" alt="Logo" title="Logo" src={clientWidth > 350 ? modal_logo : modal_logo_min} ></img>

      <Modal.Body >

        <h4>Olá!</h4>
        
        {modalSection1Content.split(`\n`).map((content, key) => <p className='ModalText' key={key + `m`}>{content}</p>)}
        <br></br>
        <h4>Termos e Condições de Uso</h4>

        {modalSection2Content.split(`\n`).map((content, key) => <p className='ModalText' key={key + `m`}>{content}</p>)}
        
        <a className="TermLink" href={'/portal/termos_e_condicoes_de_uso_pedea.pdf'} target='_blank' rel="noreferrer">Leia o texto completo dos Termos e Condições de Uso.</a>
        
        <br/><br/>
        
        <h4>Aviso de Privacidade</h4>

        {modalSection3Content.split(`\n`).map((content, key) => <p className='ModalText' key={key + `m`}>{content}</p>)}
        
        <a className="TermLink" href={'/portal/aviso_privacidade_pedea.pdf'} target='_blank' rel="noreferrer">Leia o texto completo do Aviso de Privacidade.</a>

        <br/><br/>

        <div className="WelcomeModalFooter row">
          <div className={bootstrapFooterCheckClass}>
            <div className="TermCheckBox form-check"> <input className="form-check-input" type="checkbox" checked={checkTermAndConditions} onChange={()=>setCheckTermAndConditions(checkTermAndConditions=>!checkTermAndConditions)} id="checkTermAndConditions"></input> <label className="form-check-label" htmlFor="checkTermAndConditions"> Li e concordo com os Termos de Uso da <span>PEDEA</span>.</label> </div>
            <div className="TermCheckBox form-check"> <input className="form-check-input" type="checkbox" checked={checkLegalWarning} onChange={()=>setCheckLegalWarning(checkLegalWarning=>!checkLegalWarning)} id="checkLegalWarning"></input> <label className="form-check-label" htmlFor="checkLegalWarning"> Li o Aviso de Privacidade da <span>PEDEA</span>. </label> </div>
          </div>
          <div className={bootstrapFooterButtonClass}>
            <button disabled={!(checkTermAndConditions && checkLegalWarning)} onClick={handleClose} className="btn btn-secondary btn-lg">Continuar</button>
          </div>
        </div>

      </Modal.Body>

    </Modal>
  );

}


export default WelcomeModal;