import './TemplateModal.css';
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

function TemplateModal({children, active, close, modalSize, title}) {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (active){
      handleShow();
    }else 
      handleClose();
  }, [active])

  return (
    <Modal size={modalSize} show={show} onHide={handleClose}>
      <Modal.Header ><Modal.Title>{title}</Modal.Title></Modal.Header>
      <Modal.Body >{children}</Modal.Body>
      <Modal.Footer><Button variant="secondary" onClick={handleClose}>Fechar</Button></Modal.Footer>
    </Modal>
  );

}

export default TemplateModal;