import './KMLUploader.css';
import React, { useState, useRef, useEffect } from 'react';
import KML from 'ol/format/KML';
import { Vector as VectorSource } from 'ol/source';
import { Vector } from 'ol/layer';
import { zoom, center } from '../../../../_config/map';

var vectorForKML = null;

function KMLUploader(props) {

  const htmlElement = useRef();

  const feedbackElement = useRef();

  const [data, setData] = useState(undefined);

  const zIndexer = useState(props.map.getLayers().getArray().length);

  const reader = new FileReader();

  const handleChange = (e) => {

    const files = e.target.files;

    if (files) {

      setData(files[0])

    }

  }

  const convertKMLToFeatures = () => {

    try {
      if (!data) { showErrorFeedback(); return false; }

      handleReset();

      reader.onload = function () {

        try {
          let vector = new Vector({
            name: "KML",
            source: new VectorSource({
              url: reader.result,
              format: new KML()
            }),
          });

          vector.setStyle();

          vector.setZIndex(zIndexer + 4);
          props.map.addLayer(vector);
          vectorForKML = (vector);
          props.map.getView().setZoom(zoom);
          props.map.getView().setCenter(center);
          
        } catch (error) {
          console.log(error);
          showErrorFeedback();
        }

      }

      reader.readAsDataURL(data);

      props.callback();
    } catch (error) {
      console.log(error)
      showErrorFeedback();
    }

  }

  const handleReset = () => {
    if (vectorForKML) {
      props.map.removeLayer(vectorForKML);
      vectorForKML.getSource().clear();
      vectorForKML = undefined;

    }
  }

  const showErrorFeedback = () => {
    feedbackElement.current.style.visibility = "visible";
  }

  useEffect(convertKMLToFeatures, data)

  useEffect(() => { feedbackElement.current.style.visibility = "hidden"; }, [])

  return (
    <div className="KMLUploader">

      <h6><label htmlFor="KMLFormFile" className="form-label">Importar geometrias de um arquivo KML </label></h6>

      <div className="input-group mb-3">
        <input ref={htmlElement} className="form-control" id="KMLFormFile"
          type="file"
          accept=".kml,.KML"
          onChange={handleChange}
        />
        <span onClick={handleReset} className="input-group-text KMLUploaderReset">Resetar</span>
      </div>

      <div ref={feedbackElement} className="KMLUploaderErrorFeedback">
        Houve uma falha ao tentar importar seu arquivo KML. Certifique-se de que o mesmo está no formato correto e tente novamente.
      </div>

    </div>
  )

}

export default KMLUploader;