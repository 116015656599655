import './LocateMe.css';
import { vectorForGeolocation as vector } from '../../_config/layers/';
import React, { useRef } from 'react';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import locateme_min from '../../_assets/img/locateme_min.png';
import {fromLonLat} from 'ol/proj'

function LocateMe({map}) {

  const element = useRef();

  const handleClick = () => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      alert("Geolocalização não é suportada pelo seu navegador.");
    }

  }

  function showPosition(position) {
    const coords = [position.coords.longitude, position.coords.latitude];

    const olCoords = fromLonLat(coords, 'EPSG:4326');

    // Remover pontos anteriores
    vector.getSource().clear();

    // Adicionar um novo ponto para a localização do usuário
    const userLocation = new Feature({
      geometry: new Point(olCoords)
    });

    vector.getSource().addFeature(userLocation);

    // Centralizar o mapa na localização do usuário
    map.getView().setCenter(olCoords);
    map.getView().setZoom(15);

  }

  // Função para lidar com erros de geolocalização
  function showError(error) {
    switch(error.code) {
      case error.PERMISSION_DENIED:
        alert("Usuário negou a solicitação de Geolocalização.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Informação de localização não está disponível.");
        break;
      case error.TIMEOUT:
        alert("A requisição para obter a localização expirou.");
        break;
      case error.UNKNOWN_ERROR:
        alert("Um erro desconhecido ocorreu.");
        break;
    }
  }

  return (
    <div id="LocateMe" className="LocateMe">
      <button className='HorizontalButton'
        type='button'
        alt='Rastrear a sua localização'
        title='Rastrear a sua localização'
        onClick={handleClick}
        ref={element}
      >
        <i className="material-icons">my_location</i> {/* Ícone para rastrear a sua localização */}
      </button>
    </div>
  )

}

export default LocateMe;