import React from 'react';
import './ShapeLink.css';
import icon_kml from '../../../../_assets/img/icon_kml.png';
import icon_kml_min from '../../../../_assets/img/icon_kml_min.png';



const ShapeLink = (props) => {

  const clientWidth = document.documentElement.clientWidth;

  return (
      <a className={"ShapeLink"} href={`https://pedea.sema.ce.gov.br/geoserver/wfs?request=GetFeature&service=WFS&outputFormat=application/vnd.google-earth.kml+xml&version=1.0.0&typeName=${props.wmsName.split(':')[1]}&mode=download`} download rel="noreferrer">
        <button className='HorizontalButton'
          type="button"
          title="Acessar KML do mapa"
          alt="KML"
        >
          <i className="material-icons">save_alt</i>
        </button>
      </a>
    );
}

export default ShapeLink;
