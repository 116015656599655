import './StatisticGraphics.css';
import React, { useRef, useState } from 'react';
import StatisticGraphicsModal from './component/StatisticGraphicsModal';
import statistics_min from '../../_assets/img/statistics_min.png';

function StatisticGraphics(props) {

  const restoreElement = useRef();

  const [active ,setActive] = useState(false);

  const handleClick = () =>{
    //window.activeFillModal = true;
    setActive(active => !active);
    
  }

  return (   
    <div id="StatisticGraphics" className="StatisticGraphics">
      <button className='HorizontalButton'
        type='button'
        alt='Módulos de gráficos'
        title='Módulos de gráficos'
        onClick={handleClick}
        ref={restoreElement}
      >
        <i className="material-icons">bar_chart</i> {/* Ícone para módulos de gráficos */}
      </button>
      <StatisticGraphicsModal map={props.map} active={active} callback={setActive}></StatisticGraphicsModal>
    </div>
  ) 

}

export default StatisticGraphics;