import React from 'react';
import './Toggler.css';

const Toggler = (props) => {

  const handleClick = () => {

    const element = props.switcherRef.current;

    if(element.style.visibility ==='' || element.style.visibility === 'hidden'){

      element.style.visibility = 'visible';       

      element.style.opacity = '1';

      element.style.zIndex = '4';
      
     // element.style.display = 'block';

    }
    else{
      element.style.visibility = 'hidden';

      element.style.opacity = '0';

      element.style.zIndex = '0';

     // element.style.display = 'none';

    }

  }
  
  return (
    <button className="navbar-toggler Toggler" type="button" onClick={handleClick} >
      ☰
    </button>
    );

}


export default Toggler;
