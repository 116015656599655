import React, { useState, useEffect } from 'react';
import './LocateAddressModal.css';
import { Button, Modal } from 'react-bootstrap';


const LocateAddressModal = ({ active, locateAddressInputRef, callback, handleGeocoding, address, setAddress }) => {

  const clientWidth = document.documentElement.clientWidth;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (active)
      handleShow();
    else
      handleClose();
  }, [active])

  useEffect(() => {
    callback(show)
  }, [show])

  const handleSubmit = (e) => {
    e.preventDefault(); 
    handleGeocoding(); 
    return false;
  }

  return (

    <Modal className="LocateAddressModal" backdrop="static" size={`m`} show={show} onHide={handleClose}>

      <Modal.Header ><Modal.Title>Buscador de endereço</Modal.Title></Modal.Header>


      <Modal.Body >

        <form className="row needs-validation" onSubmit={handleSubmit}>
          <div className="col-12">

            <div className="input-group input-group mb-3">
              <input onChange={(e) => setAddress(e.target.value)} value={address} placeholder={"Digite o endereço que você procura..."} className={"form-control LocateAddressInput"} ></input>
              <span ref={locateAddressInputRef} onClick={handleGeocoding} className="input-group-text LocateAddressButton" id="basic-addon1">🔎</span>
              <div className="valid-feedback">
                Procurando endereço...
              </div>
              <div className="invalid-feedback">
                Endereço não encontrado.
              </div>
            </div>

          </div>
        </form>

      </Modal.Body>
      <Modal.Footer><Button variant="secondary" onClick={handleClose}>Fechar</Button></Modal.Footer>
    </Modal>
  );

}


export default LocateAddressModal;