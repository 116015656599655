import './FeatureInfo.css';
import React, { useState, useEffect, useRef } from 'react';
import { createTitle, createRecord } from '../../utils/JSXHandler';
import TemplateModal from '../../components/TemplateModal/TemplateModal';
import featureInfo from '../../_assets/img/featureInfo.png';
import featureInfo_min from '../../_assets/img/featureInfo_min.png';
import { vector } from '../../_config/layers/';

function FeatureInfo(props) {

  const htmlElement = useRef();

  const [modalValue, setModalValue] = useState('');

  const [active, setActive] = useState(0);

  const [enabled, setEnabled] = useState(false);

  const clientWidth = document.documentElement.clientWidth;

  const checkQueryableLayers = () => {

    let queryableLayers = [];

    for (let i = 0; i < props.map.getLayers().getArray().length; i++) {
      if (props.map.getLayers().getArray()[i].getVisible() && props.map.getLayers().getArray()[i].get('queryable')) {
        queryableLayers.push(props.map.getLayers().getArray()[i]);
      }
    }

    return queryableLayers;

  }

  const requestFeatureInfo = async (evt, queryableLayers) => {

    let featureInfo = [];

    vector.getSource().clear();

    for (let i = 0; i < queryableLayers.length; i++) {
      let view = props.map.getView();
      let viewResolution = view.getResolution();
      let source = queryableLayers[i].getSource();
      let url = source?.getFeatureInfoUrl(
        evt.coordinate, viewResolution, view.getProjection(),
        { 'INFO_FORMAT': 'application/json', 'FEATURE_COUNT': 50 });
      if (url) {
        await fetch(url)
          .then((response) => {
            return response.json().then((data) => {
              if (data && data.features && data.features.length > 0) {
                featureInfo.push(createTitle(queryableLayers[i].get('title')));
                for (let i = 0; i < data.features.length; i++)
                  featureInfo.push(createRecord(i, data.features[i].properties));
                return true;
              }
              else
                return false;
            }).catch((err) => {
              console.log(err);
            })
          });

      }
    }

    return featureInfo;

  }

  const getEnabled = () => enabled;


  useEffect(() => {

    window.activeFillModal = false;

    props.map.on('singleclick',
      async (evt) => {

        if (window.activeFillModal) {

          vector.getSource().clear();
          
          const coordinate = evt.coordinate;

          const coordinateWarning = (<span key='featureCoordinates'><h5>Coordenadas do click</h5><span className='FeatureField'> Latitude</span>: {(coordinate[0])}, <span className='FeatureField'>Longitude</span>: {(coordinate[1])}</span>);

          let queryableLayers = checkQueryableLayers();

          let featureInfo = await requestFeatureInfo(evt, queryableLayers);

          if (featureInfo.length > 0) {
            setModalValue([...featureInfo, (<hr key='format' />), coordinateWarning]);
          }

          setActive(active => active + 1);

          console.log(active)

        }
      }
    );

  }, [props.map])

  const handleClick = () => {
    setEnabled(enabled => !enabled);
  }

  useEffect(() => {

    window.activeFillModal = enabled;
    
  }, [enabled])

  return (
    <>
         <button
        type='button'
        alt='Informação: clique em um local do mapa para ver mais informações.'
        title='Informação: clique em um local do mapa para ver mais informações.'
        onClick={handleClick}
        className={"navbar-toggler FeatureInfo " + (enabled ? "FeatureInfoActive" : "FeatureInfoDeactive")}
        ref={htmlElement}
      >
        <i className="material-icons">info</i>
      </button>
      <TemplateModal active={active} className="FeatureInfoModal" title={`Dados tabulares`}>{modalValue}</TemplateModal>
    </>
  )

}

export default FeatureInfo;