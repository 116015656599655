import './StoryTelling.css';
import React, { useRef, useState } from 'react';
import StoryTellingModal from './component/StoryTellingModal';

function StoryTelling(props) {

  const restoreElement = useRef();

  const [active ,setActive] = useState(false);

  const handleClick = () =>{
    //window.activeFillModal = true;
    setActive(active => !active);
    
  }

  return (   
    <div id="StoryTelling" className="StoryTelling">
      <button className='HorizontalButton'
        type='button'
        alt='Módulos de gráficos'
        title='Módulos de gráficos'
        onClick={handleClick}
        ref={restoreElement}
      >
        <i className="material-icons">query_stats</i> {/* Ícone para módulos de gráficos */}
      </button>
      <StoryTellingModal map={props.map} active={active} callback={setActive}></StoryTellingModal>
    </div>
  ) 

}

export default StoryTelling;