import React from 'react';
import './Navbar.css';
import Logo from './component/Logo';
import Toggler from './component/Toggler';

const Navbar = (props) => {
  
  return (
    <nav className="navbar navbar-dark Navbar">
      <div className="container-fluid">
            <Logo/>
            {props.children}
            <Toggler switcherRef={props.switcherRef}/>
      </div>
    </nav>
    );
}


export default Navbar;
