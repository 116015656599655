import React, { useState, useEffect } from 'react';
import './StatisticGraphicsModal.css';
import { Chart } from 'chart.js/auto';
import { ServerTypeHelper } from '../../../../_config/helpers';
import modal_logo from '../../../../_assets/img/modal_logo.png'
import modal_logo_min from '../../../../_assets/img/modal_logo_min.png'
import { Modal } from 'react-bootstrap';


const StatisticGraphicsModal = ({ active, callback, map }) => {

  const clientWidth = document.documentElement.clientWidth;

  const layers = [...map.getLayers().getArray()];

  const [layerOptions, setLayerOptions] = useState([]);

  const [charterWidth, setCharterWidth] = useState([]);

  const [charterHeight, setCharterHeight] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  useEffect(() => {
    if (active)
      handleShow();
  }, [active])

  useEffect(() => {
    callback(show)
  }, [show])

  useEffect(() => {
    buildLayerSelect();
  }, [])

  const buildLayerOption = (layer, id) => {

    return (<option key={id} value={layer.get('wmsName').split(':')[1]}>{layer.get(`title`)}</option>);

  }

  function buildLayerSelect() {
    const layerOptions = layers
      .filter(layer => layer.get('isAllowedToStatistics'))
      .map((layer, key) => buildLayerOption(layer, key));

    return setLayerOptions(layerOptions);

  }

  /*const buildLayerSelect = () => {

    return setLayerOptions(layers.map((layer, key) => layer.get(`serverType`) === ServerTypeHelper.GEOSERVER && buildLayerOption(layer, key)));

  }*/

  function loadAttributes() {
    const layerName = document.getElementById('layerName').value;
    const url = `https://pedea.sema.ce.gov.br/api/v1/attributes/layer?layer=${layerName}`;

    showLoader(true);

    fetch(url)
      .then(response => response.text())
      .then(data => {
        const attributes = data.match(/[\w]{1,}:/g).map(attr => attr.slice(0, -1));
        const attributeTypes = data.match(/[\w]{1,};/g).map(type => type.slice(0, -1));
        populateAttributes(attributes, attributeTypes);
        showLoader(false);
      })
      .catch(error => {
        console.error('Erro ao carregar os atributos:', error);
        showLoader(false);
      });
  }

  function populateAttributes(attributes, attributeTypes) {
    let fieldX = document.getElementById('fieldX');
    let fieldY = document.getElementById('fieldY');

    fieldX.innerHTML = '';
    fieldY.innerHTML = '';

    attributes.forEach((attr, index) => {
      const type = attributeTypes[index];
      if (isTextualOrDate(type) || attr.includes("ano")) {
        const optionX = document.createElement('option');
        optionX.value = attr;
        optionX.textContent = attr;
        fieldX.appendChild(optionX);
      }
      if (isNumericOrDate(type)) {
        const optionY = document.createElement('option');
        optionY.value = attr;
        optionY.textContent = attr;
        fieldY.appendChild(optionY);
      }
    });
  }

  function isTextualOrDate(type) {
    return ['character', 'varchar', 'text', 'date', 'timestamp'].some(t => type.startsWith(t));
  }

  function isNumericOrDate(type) {
    return ['integer', 'int', 'float', 'double', 'numeric', 'date', 'timestamp'].some(t => type.startsWith(t));
  }

  function generateChart(event) {
    event.preventDefault();

    const layerName = document.getElementById('layerName').value;
    const chartType = document.getElementById('chartType').value;
    const fieldX = document.getElementById('fieldX').value;
    const fieldY = document.getElementById('fieldY').value;
    const url = `https://pedea.sema.ce.gov.br/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&outputFormat=application/json`;

    showLoader(true);

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const xValues = [];
        const yValues = [];

        data.features.forEach(feature => {
          xValues.push(feature.properties[fieldX]);
          yValues.push(feature.properties[fieldY]);
        });

        const aggregatedData = aggregateData(xValues, yValues);
        renderChart(chartType, aggregatedData.xValues, aggregatedData.yValues, fieldX, fieldY);
        showLoader(false);
      })
      .catch(error => {
        console.error('Erro ao carregar os dados:', error);
        showLoader(false);
      });
  }

  function aggregateData(xValues, yValues) {
    let aggregatedData = {};
    xValues.forEach((x, index) => {
      if (!aggregatedData[x]) {
        aggregatedData[x] = 0;
      }
      aggregatedData[x] += yValues[index];
    });

    return {
      xValues: Object.keys(aggregatedData),
      yValues: Object.values(aggregatedData)
    };
  }

  function renderChart(chartType, xValues, yValues, fieldX, fieldY) {

    const sizeConstant = 80;

    const pieSizeConstant = 600;

    const ctx = document.getElementById('charter').getContext('2d');

    resetChart();

    const backgroundColors = generateRandomColors(xValues.length);
    const borderColors = backgroundColors.map(color => color.replace('0.2', '1'));

    setCharterWidth(chartType === 'pie' ? pieSizeConstant : xValues.length * sizeConstant);
    setCharterHeight(chartType === 'pie' ? pieSizeConstant / 2 : (xValues.length * sizeConstant) / 2);

    document.getElementById("chartContainer").style.width = chartType === 'pie' ? pieSizeConstant : `${xValues.length * sizeConstant}px`;
    document.getElementById("chartContainer").style.height = chartType === 'pie' ? pieSizeConstant / 2 : `${(xValues.length * sizeConstant) / 2}px`;

    window.myChart = new Chart(ctx, {
      type: chartType,
      data: {
        labels: xValues,
        datasets: [{
          label: `${fieldY} por ${fieldX}`,
          data: yValues,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            display: chartType !== 'pie'
          },
          x: {
            display: chartType !== 'pie',
            ticks: {
              font: {
                size: 10 // Ajuste o tamanho da fonte aqui
              },
              autoSkip: false, // Para não pular labels
              maxRotation: 60, // Para manter os labels na vertical
              minRotation: 60  // Para manter os labels na vertical
            }
          }
        }
      }
    });

    document.getElementById('exportBtn').style.display = 'block';
  }

  function resetChart() {
    if (window.myChart) {
      window.myChart.destroy();
    }
    document.getElementById('charter').getContext('2d').clearRect(0, 0, document.getElementById('charter').width, document.getElementById('charter').height);
    document.getElementById('exportBtn').style.display = 'none';
  }

  function exportChart() {
    const canvas = document.getElementById('charter');
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');


    tempCanvas.width = charterWidth + 400;
    tempCanvas.height = charterHeight + 400;

    // Fundo branco
    tempCtx.fillStyle = '#ffffff';
    tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

    // Adicionar o título do layer
    const layerName = document.getElementById('layerName').options[document.getElementById('layerName').selectedIndex].text;
    tempCtx.fillStyle = 'black';
    tempCtx.font = '10px Arial';
    const titleHeight = 20; // Altura do texto do título
    tempCtx.fillText(`Fonte: PEDEA SEMA/CE | Camada: ${layerName}`, 10, titleHeight);

    // Desenhar o gráfico no canvas temporário, abaixo do título
    const chartTop = titleHeight + 20; // Adiciona uma margem abaixo do título
    tempCtx.drawImage(canvas, 0, chartTop, tempCanvas.width, tempCanvas.height - chartTop);


    const link = document.createElement('a');
    link.href = tempCanvas.toDataURL('image/png');
    link.download = 'grafico.png';
    link.click();
  }

  function showLoader(show) {
    document.getElementById('loader').style.display = show ? 'block' : 'none';
  }

  function generateRandomColors(numColors) {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      colors.push(`rgb(${r}, ${g}, ${b})`);
    }
    return colors;
  }

  return (

    <Modal className="StatisticGraphicsModal" size={`xl`} show={show} onHide={handleClose} data-keyboard="false" data-backdrop="static">

      <img className="img-thumbnail" alt="Logo" title="Logo" src={clientWidth > 350 ? modal_logo : modal_logo_min} ></img>


      <Modal.Body >

        <div className="container mt-5">
          <h1 class="text-center">Geração de Gráficos</h1>
          <form id="chartForm" className="mt-4">
            <div className="mb-3">
              <label for="layerName" className="form-label">Camada de referência</label>
              <select className="form-control" id="layerName" required onChange={loadAttributes}>
                <option value="">Selecione uma camada...</option>
                {layerOptions}
              </select>
            </div>
            <div id="graphicType" className="mb-3">
              <label for="chartType" className="form-label">Tipo de Gráfico</label>
              <select className="form-control" id="chartType" required>
                <option value="line">Linha</option>
                <option value="bar">Barra</option>
                <option value="pie">Pizza</option>
              </select>
            </div>
            <div className="mb-3">
              <label for="fieldX" className="form-label">Campo agregador</label>
              <select className="form-control" id="fieldX" required></select>
            </div>
            <div className="mb-3">
              <label for="fieldY" className="form-label">Campo de dados</label>
              <select className="form-control" id="fieldY" required></select>
            </div>
            <button type="submit" onClick={generateChart} className="btn btn-primary">Gerar Gráfico</button>

          </form>

          <div id="loader" className="text-center mt-3">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>

          <div id="chartContainer">
            <canvas id="charter"></canvas>
          </div>
          <button id="exportBtn" onClick={exportChart} className="btn btn-success mt-3" >Exportar Gráfico</button>
        </div>

      </Modal.Body>

      <Modal.Footer>

        <div className="row g-3">
          <div className="d-grid col-3">
            <button onClick={handleClose} className="btn btn-secondary btn-lg">Fechar</button>
          </div>
        </div>

      </Modal.Footer>

    </Modal>
  );

}


export default StatisticGraphicsModal;