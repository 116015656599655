import Map from 'ol/Map';
import View from 'ol/View';
import {layers, explorer} from './layers/';

const zoom = 7;
const center = [-39.6956, -4.7907];
const projection = 'EPSG:4326';

const map = new Map({
   layers: layers,
    view: new View({
      projection: projection,
      center: center,
      zoom: zoom,
      maxZoom:18
    }),
    controls: [],
  });

  export {map, zoom, center, projection, explorer};
