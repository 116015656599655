import React from 'react';
import './GovFooterBar.css';

const GovFooterBar = (props) => {
  
  return (
      <div className="GovFooterBar">
          <span className="GovFooterBarText">Dados do mapa © 2022 Governo do Estado do Ceará. Todos os direitos reservados.</span>
          <a className="GovFooterBarLink" href={'/portal/termos_e_condicoes_de_uso_pedea.pdf'} target='_blank' rel="noreferrer">Termos e Condiçẽs de Uso</a>
          <a className="GovFooterBarLink" href={'/portal/aviso_privacidade_pedea.pdf'} target='_blank' rel="noreferrer">Aviso de Privacidade</a>
      </div>
    );
}


export default GovFooterBar;
