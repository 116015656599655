import './HintWindow.css';
import React, { useRef, useState } from 'react';
import HintModal from './component/HintModal';
import hint_min from '../../_assets/img/hint_min.png';
import { vector } from '../../_config/layers/';



function HintWindow(props) {

  const restoreElement = useRef();

  const [active ,setActive] = useState(false);

  const handleClick = () =>{
    //window.activeFillModal = true;
    setActive(active => !active);
    vector.getSource().clear();

    
  }

  return (   
    <div id="HintWindow" className="HintWindow">
      <button className='HorizontalButton'
        type='button'
        alt='Consultar ajuda'
        title='Consultar ajuda'
        onClick={handleClick}
        ref={restoreElement}
      >
        <i className="material-icons">help_outline</i> {/* Ícone para consultar ajuda */}
      </button>

      <HintModal active={active} callback={setActive}></HintModal>
    </div>
  ) 

}

export default HintWindow;